

.liner {
    flex-direction: row;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 150%;
}
.liner > div {
    width: 100%;
    margin: 0 1%;
}

.liner > .conforme{
    display: grid
    
}

.send{
    margin: 8% 0 0 130%;
} 

.file {
    margin: 5% 0 0 0;
}
.form_group{
    padding: 1%;
}

@media (min-width : 375px) and (max-width : 768px) {
    .liner {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap : 1rem;
        grid-auto-rows: minmax(10px, auto);
        padding-top : 1rem;
    }
    .supprime {
        grid-column: 1 / 2;
    }
    .liner > .conform {
        grid-column: 1 / 3;
    }
    .liner > .conforme {
        grid-column: 1 / 3;
    }
    .supprimer {
        grid-row: 4 / 4;
        display: flex;
    }
    .send{
        margin: 1% 0 0 0;
        padding-top: 10%;
    }
    
}

@media (min-width : 768px) and (max-width : 1024px) {

.liner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap : 1rem;
    grid-auto-rows: minmax(10px, auto);
}
.liner > .conforme {
    grid-column: 1/ 3;
}
.supprimer {
    grid-row: 4 / 4;
    display: flex;
}
.liner > .conform {
    grid-column: 1 / 3;
    padding-top: 1rem;
}
.send{
    margin: 1% 0 0 0;
    padding-top: 10%;
}

}


