.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 30%;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 10%;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 10%;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 5%;
}

.tablePermit table th:nth-child(1),
.tablePermit table td:nth-child(1) {
  width: 30%;
}

.tablePermit table th:nth-child(2),
.tablePermit table td:nth-child(2) {
  width: 10%;
}

.tablePermit table th:nth-child(3),
.tablePermit table td:nth-child(3) {
  width: 10%;
}

.tablePermit table th:nth-child(4),
.tablePermit table td:nth-child(4) {
  width: 20%;
}

.tablePermit table th:nth-child(5),
.tablePermit table td:nth-child(5) {
  width: 10%;
}


.green {
  color: green;
}

.orange {
  color: orange;
}

.hidden {
  display: none;
}

.Nohidden {
  display: block;
}

.table{
  width: 100%;
}

@media (max-width: 80rem) {
  .table {
    width: 100%;
    font-size: 68%;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.columns {
  display: flex;
  flex-direction: column;
  align-items: end;
}