.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 12%;
}
.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 10%;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 15%;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 15%;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 10%;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 10%;
}

.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 20%;
}

.main {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
}

.titre{
  color: rgb(24, 41, 192);
  font-size: 1.5vh;
  font-weight: 700;
}
.titre2{
  margin-left: 27rem;
  padding-top: 2vh;
}

.close{
  margin-left: 70rem;
  position: relative;
  top: -2.5rem;
  cursor: pointer;
  color: red !important;
}

.close:hover{
  color: rgb(255, 0, 0);
}

.div{
margin-top: 2rem;
margin-bottom: -1rem;
}

.search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}