.wrapper {
  overflow-x: auto;
  border-radius: 0.35rem;
}
.formulaire {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3px;
  grid-auto-rows: minmax(10px, auto);
  margin-bottom: 1vw;
}

.form_delete button {
  --gradient1: rgb(141, 5, 5);
  --gradient2: rgb(145, 11, 34);
  flex-shrink: 0;
  color: var(--color-white);
  margin-top: 1vw;
  margin-left: 2vh;
  background: linear-gradient(0deg, var(--gradient1) 32%, var(--gradient2) 67%);
}
.form_succes button {
  --gradient1: rgba(39, 49, 128, 1);
  --gradient2: rgba(57, 87, 195, 1);
  flex-shrink: 0;
  margin-top: 1vw;
  margin-left: 2vh;
  color: var(--color-white);
  background: linear-gradient(0deg, var(--gradient1) 32%, var(--gradient2) 67%);
}

.disable {
  color: var(--color-grey-400) !important;
  pointer-events: none;
  cursor: default;
}
.pencolor svg {
  color: var(--color-grey-400) !important;
}
.cell {
  justify-content: center;
  width: 1vw;
  color: var(--color-primary-500);
  line-height: 1.5;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-black-200);
  overflow-wrap: break-word;
}
.cellHeaderCheck {
  color: var(--color-grey-400);
  background-color: var(--color-grey-200);
  font-weight: 900;
  line-height: 1.2;
  text-align: left;
  height: 3%;
  border-color: var(--color-grey-300);
}
.backblue {
  background-color: var(--color-primary-100) !important;
}
.cellCheck {
  text-align: center;
  align-items: flex-start;
  justify-content: left;
  padding: auto;
  color: var(--color-primary-500);
  line-height: 1.5;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-black-200);
  overflow-wrap: break-word;
}
.buttondisac {
  justify-content: center;
  width: 8vw;
  color: var(--color-grey-400);
  line-height: 1.5;
  text-transform: uppercase;
  overflow-wrap: break-word;
}
.cellHeader {
  color: var(--color-grey-400);
  background-color: var(--color-grey-100);
  font-weight: 900;
  line-height: 1.6;
  text-transform: uppercase;
  text-align: left;
  border-color: var(--color-grey-200);
}
.button {
  justify-content: flex-start;
  text-align: center;
  padding: auto;
  width: 10vw;
}
.ButtonAction {
  justify-content: center;
  padding: auto;
  color: var(--color-primary-500);
  line-height: 1.5;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-black-200);
  overflow-wrap: break-word;
}
.cellBut {
  color: var(--color-grey-400);
  background-color: var(--color-grey-100);
  font-weight: 900;
  line-height: 1.2;
  text-align: end;
  border-color: var(--color-grey-200);
  width: 3vw;
}
.cellActive {
  color: var(--color-grey-400);
  background-color: var(--color-grey-100);
  font-weight: 900;
  line-height: 1.2;
  text-align: left;
  border-color: var(--color-grey-200);
  width: 3vw;
}
.cellbutton {
  color: var(--color-primary-500);
  background-color: var(--color-grey-200);
  font-weight: 900;
  line-height: 1.2;
  text-align: left;
  height: 3%;
  border-color: var(--color-grey-300);
}
.cellActif {
  justify-content: center;
  padding: auto;
  color: var(--color-primary-500);
  line-height: 1.5;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-black-200);
  overflow-wrap: break-word;
}
.cellComment {
  color: var(--color-primary-500);
  background-color: var(--color-grey-200);
  font-weight: 900;
  line-height: 2;
  height: 3%;
  border-color: var(--color-grey-300);
}
.cellCommentaire {
  justify-content: center;
  text-align: center;
  color: var(--color-primary-500);
  line-height: 1.5;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-black-200);
  overflow-wrap: break-word;
}

.form {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.lastcomment {
  text-align: start !important;
  margin-top: 10px;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.noResults {
  text-transform: lowercase;
}

.center {
  text-align: center;
  align-items: flex-start;
  justify-content: left;
  padding: auto;
  color: var(--color-primary-500);
  line-height: 1.5;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-primary-100);
  overflow-wrap: break-word;
}

.add {
  position: sticky;
  left: 65vw;
  justify-content: center;
  margin-top: 10px;
}

.barre {
  border-bottom: 1px solid rgba(21, 2, 105, 0.144);
  padding-bottom: 0.75rem;
}
.cellAction {
  justify-content: center;
  padding: auto;
  color: var(--color-primary-500);
  line-height: 1.5;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-black-200);
  overflow-wrap: break-word;
}

.cellHeaderObs {
  color: var(--color-primary-500);
  background-color: var(--color-grey-200);
  font-weight: 900;
  line-height: 1.2;
  text-align: left;
  height: 3%;
  border-color: var(--color-grey-300);
}
.cellObs {
  width: 5vw;
  justify-content: center;
  text-align: center;
  color: var(--color-primary-500);
  line-height: 1.5;
  padding: auto;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-black-200);
  overflow-wrap: break-word;
}
.cellHeaderName {
  color: var(--color-primary-500);
  background-color: var(--color-grey-200);
  font-weight: 900;
  line-height: 1.2;
  text-align: left;
  height: 3%;
  border-color: var(--color-grey-300);
}
.cellName {
  width: 15vw;
  justify-content: center;
  text-align: left;
  color: var(--color-primary-500);
  line-height: 1.5;
  height: 3%;
  padding: auto;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-black-200);
  overflow-wrap: break-word;
}
.form_submit {
  grid-column: 3;
  grid-row: 4;
  padding: 0px !important;
  background-color: #ffffff;
  width: 100%;
  margin-top: 0vw !important;
}

.form_input {
  display: block;
  width: 25vw;
  justify-content: center;
  align-items: center;
  padding: var(--padding-s) var(--padding-m);
  font-family: var(--font-primary-regular);
  font-size: var(--fs-s);
  line-height: 2;
  grid-column: 2;
  grid-row: 4;
  margin-top: -0.5vh;
  color: var(--color-primary-500);
  border: 1px solid #dadada;
  border-radius: 0.3rem;
}
.getcommentaire {
  display: inline-block;
  width: 100%;
  align-items: flex-end;
  background-color: #ffffff;
  overflow-y: auto;
}

.divcommentaire {
  background-color: white;
  color: var(--color-primary-500);
  font-size: 1.25vh;
  border-radius: 1vh;
  padding: 1vh;
  margin-top: 1vh;
  border: solid 1px var(--color-primary-500);
}

.Titlecommentaire {
  font-size: 2vh !important;
  font-weight: 900;
}
.buttoncommentaire {
  padding: var(--padding-s) var(--padding-m);
  color: var(--color-white);
  background-color: var(--color-error-600);
  font-size: var(--fs-m);
  border-radius: 0.5rem;
  cursor: pointer;
}
.usercommentaire {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: larger;
}

.usercommentaire p {
  margin: 0;
}

.commentaire {
  font-size: 1.5vh;
  margin-top: 1vh;
  font-weight: 900;
  margin-bottom: 1vh;
  text-align: center;
}

.comment {
  font-size: 1.6vh;
  margin-top: 1vh;
  font-weight: 900;
  margin-bottom: 1vh;
}

.form_cancel {
  grid-column: 1;
  grid-row: 4;
  margin-top: -1vw !important;
  margin-left: 1vw;
  background-color: #ffffff;
  margin-top: 0vw !important;
}
.form_buttons {
  display: flex;
}
@media (max-width: 60rem) {
  .form_delete Button {
    padding: 1rem 5rem;
  }
  .form_succes Button {
    padding: 1rem 5rem;
  }
}

@media (min-width: 1246px) and (max-width: 1560px) {
  .form_input {
    width: 20vw;
    grid-column: 2;
    grid-row: 4;
  }
  .form_submit {
    grid-column: 3;
    grid-row: 4;
    margin-top: -1vw !important;
  }
  .form_cancel {
    grid-column: 1;
    grid-row: 4;
  }
}
.table {
  width: 100%;
  display: table;
}

.cellMobile {
  padding: 0.5rem;
  color: var(--color-grey-500);
  line-height: 1.2;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-primary-100);
  overflow-wrap: break-word;
}

.cellHeaderNameMobile {
  padding: 0.5rem;
  color: var(--color-grey-500);
  line-height: 1.2;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-primary-100);
  overflow-wrap: break-word;
  font-weight: 700;
}
.icon {
  color: rgb(21, 137, 116);
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}
.cells {
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(248, 244, 244, 0.1);
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid var(--color-grey-200);
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
  }
}

/* drowndown menu */
.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(248, 244, 244, 0.1);
  padding: 10px;
  font-size: 14px;
  position: relative;
  margin-top: 3px;
}
.dropdown .dropdown-content {
  transition: 1s all ease-in-out;
}
.dropdown_content {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 10px;
  margin: 10px;
  font-size: 14px;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* transition: 1s all ease-in-out; */
  /* transition: cubic-bezier(0.075, 0.82, 0.165, 1); */
  /* @animation: dropdown 0.5s ease-in-out; */
}
.dropdown__button {
  width: 100%;
  margin-bottom: 1vh;
}
.photobtn {
  width: 100%;
  margin-bottom: 1vh;
  background-color: var(--color-primary-500);
  color: #fff;
  border-radius: 1.75rem;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-m);
  font-family: var(--font-primary-regular);
  font-size: var(--fs-m);
  text-transform: uppercase;
  cursor: pointer;
}
.Regis_internebtn {
  width: 100%;
  margin-bottom: 1vh;
  background-color: var(--color-primary-500);
  color: #fff;
  border-radius: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-m);
  font-family: var(--font-primary-regular);
  font-size: var(--fs-m);
  text-transform: uppercase;
  cursor: pointer;
}
.Organismebtn {
  width: 100%;
  margin-bottom: 1vh;
  background-color: var(--color-primary-500);
  color: #fff;
  border-radius: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-m);
  font-family: var(--font-primary-regular);
  font-size: var(--fs-m);
  text-transform: uppercase;
  cursor: pointer;
}
.deletebtn {
  background-color: var(--color-error-500) !important;
  color: var(--color-white);
  width: 100%;
}
.action_button {
  padding: var(--padding-m) var(--padding-l);
  color: var(--color-white);
  font-size: var(--fs-m);
  font-weight: 700;
  border-radius: 1.75rem;
  cursor: pointer;
  width: 100%;
  margin-bottom: var(--margin-s);
}

.devis_button {
  padding: var(--padding-m) var(--padding-l);
  color: var(--color-white);
  font-size: var(--fs-m);
  font-weight: 700;
  border-radius: 1.75rem;
  cursor: pointer;
  width: 100%;
  margin-bottom: var(--margin-s);
  background-color: green !important;
}
.formulaire {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 0.5vh;
  font-size: 2vh;
  position: relative;
}

.formulaire Input {
  width: 100%;
  padding-left: 10vh;
  padding-right: 2vh;
}

.form_groupsubmit {
  color: var(--color-white);
  font-size: var(--fs-xs);
  border-radius: 1.75rem;
  cursor: pointer;
  width: 25vh;
  margin-bottom: 1vh;
  margin-top: 1vh;
  background-color: rgb(200, 29, 29) !important;
}

.form_groupsubmits {
  color: var(--color-white);
  font-size: var(--fs-xs);
  border-radius: 1.75rem;
  cursor: pointer;
  width: 25vh;
  margin-bottom: 1vh;
  margin-top: 1vh;
  background-color: var(--color-primary-500);
}

.action {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
  background-color: var(--color-white);
  border: 0;
  border-radius: 0.3125rem;
  cursor: pointer;
}

.action:hover {
  background-color: var(--color-grey-200);
}

.action:not(:last-child) {
  margin-right: 0.5rem;
}

.action svg {
  width: 1rem;
  height: 1rem;
  color: var(--color-success-600);
}

.table {
  table-layout: fixed;
  width: 100%;
  display: table;
  height: 10%;
  background-color: var(--color-white);
  border-collapse: collapse;
}

.table .tableHeader th:nth-child(1),
.table .tableBody td:nth-child(1) {
  width: 4%;
  text-align: center;
}

.table .tableHeader th:nth-child(2),
.table .tableBody td:nth-child(2) {
  width: 20%;
}

.table .tableHeader th:nth-child(3),
.table .tableBody td:nth-child(3) {
  width: 10%;
  text-align: center;
}

.table .tableHeader th:nth-child(4),
.table .tableBody td:nth-child(4) {
  width: 30%;
  align-items: center;
}
.table .tableHeader th:nth-child(5),
.table .tableBody td:nth-child(5) {
  width: 10%;
  text-align: center;
}

@media (max-width: 80rem) {
  .table {
    width: 100%;
    font-size: 68%;
  }

}
.search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.commentaire {
  position: relative;
  display: inline-block;
}

.badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 12px;
}

.noBadge{
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: var(--color-grey-300);
    color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 12px;
}

.sendComment {
  display: flex;
  margin-bottom: 20px;
}

.form_textarea {
  display: block;
  width: 100%;
  padding: var(--padding-s) var(--padding-m);
  font-family: var(--font-primary-regular);
  font-size: var(--fs-s);
  line-height: 2;
  color: var(--color-primary-500);
  border: 1px solid var(--color-primary-500);
  border-radius: 0.3rem 0 0 0.3rem;
  border-right: none;
}

.submit_comment {
  padding: var(--padding-s) var(--padding-m);
  color: var(--color-white);
  background-color: var(--color-primary-500);
  font-size: var(--fs-m);
  border-radius: 0 0.3rem 0.3rem 0;
  cursor: pointer;
}

.iconDevis {
  color: green;
  padding-right: 0.5rem;
  padding-bottom: 0.2rem;
}

.cursor {
  cursor: pointer;
}
