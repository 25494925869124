.canvas {
  width: 100%;
  height: 100%;
  }

  .trimbtn{
    background-color: var(--color-primary-500) !important;
    color: white !important;
  }
  
  .clearbtn{
    background-color: var(--color-error-500) !important;
    color: white !important;
  }
  .signatureContainer{
    margin-top: 5vh;
    display: flex;
  }

  .img{
    background-color: rgba(135, 135, 135, 0.244);
    width: 50vh;
    height: 20vh;
  }