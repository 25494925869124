.input {
  display: block;
  width: 100%;
  padding: var(--padding-s) var(--padding-m);
  font-family: var(--font-primary-regular);
  font-size: var(--fs-s);
  line-height: 2;
  color: var(--color-primary-500);
  background-color: var(--color-white);
  border: 1px solid #dadada;
  border-radius: 0.3rem;
}

input:disabled {
  color: hsl(0, 0%, 60%);
  background-color: hsl(0, 0%, 95%);
}

.label {
  display: inline-block;
  margin-bottom: var(--margin-xs);
  color: var(--color-primary-500);
  font-size: var(--fs-s);
  text-transform: uppercase;
}

.inputResponsive{
  display: block;
  width: 100%;
  padding: var(--padding-s) var(--padding-m);
  font-family: var(--font-primary-regular);
  font-size: var(--fs-s);
  line-height: 2;
  color: var(--color-primary-500);
  background-color: var(--color-white);
  border: 1px solid #dadada;
  border-radius: 0.3rem;
}

@media (max-width: 768px) {
  .input {
    display: flex;
    justify-content: center;
  }

  .inputResponsive{
    display: flex;
    justify-content: center;
    width: 40%;
  }
}