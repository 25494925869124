.headerChoice {
  padding: 0.5rem 1rem;
  width: 100%;
  border-top: 5px solid var(--color-primary-500);
  border-radius: .35rem;
  background-color: var(--color-white);
  text-align: center;
}
.headerChoice h1 {
  margin: 0;
}

.sectionChoice {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
}

.choiceWrapper {
  border-radius: .35rem;
  overflow: hidden;
  background-color: var(--color-white);
  transform: scale(1);
  transition: 0.1s ease;
}
.choiceWrapper:hover {
  transform: scale(1.03);
  transition: 0.1s ease;
}

.backgroundBooklet {
  width: 100%;
  height: 2rem;
  background-size: cover;
}

.choiceBooklet {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 48rem) {
  .sectionChoice {
    grid-template-columns: 1fr;
  }
}

.shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}