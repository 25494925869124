.main {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
}

h1 {
    margin: 10px;
    font-size: 40px;
    color: rgb(255, 255, 255);
}

.number_contact{
    color : gray;
    padding-bottom : 1%;
}

@media (max-width:600px) {
    h1 {
        font-size: 30px;
    }
    .accordion__item {
        width: 80%;
    }
}