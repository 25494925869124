.search {
  position: inline-block;
  margin-left: relative;
}

.search svg {
  display: block; 
  margin-bottom: 5px;
  position: absolute;
  top: 0.875rem;
  right: 1.375rem;
  color: var(--color-grey-300);
}

.input {
  display: block;
  padding: 0.5rem 3rem 0.5rem 1rem;
  color: var(--color-grey-400);
  font-family: var(--font-primary-regular);
  font-size: 1rem;
  line-height: 1.5;
  background-color: var(--color-white);
  border: 2px solid var(--color-grey-200);
  border-radius: 1.875rem;
}
