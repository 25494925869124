.adminGrid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
  }

  .container {
    box-shadow: 0px 4px 15px rgba(51, 51, 51, 0.2);
    border: 1px solid #dadada;
    border-radius: 5px;
  }
  
  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 16.25rem;
  }
  
  .title {
    text-align: left;
    color: var(--color-primary-500);
    margin: 0;
  }
  
  .titleNumber {
    font-weight: 400;
    font-size: var(--fs-l);
    line-height: var(--fs-xl);
    text-transform: uppercase;
  }
  .number {
    font-weight: 700;
    font-size: var(--fs-xxl);
    line-height: var(--fs-xl);
    margin-top: 1rem;
  }
  
  .pictoCard {
    flex-shrink: 0;
  }
  
  .fileDownload {
    color: var(--color-primary-500);
    font-size: calc(var(--fs-xxl)*2);
  }
  
  @media (max-width: 80rem) {
    .adminGrid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 60rem) {
    .adminGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 40rem) {
    .adminGrid {
      grid-template-columns: 1fr;
    }
  }
  
  .headerChoice {
    padding: 0.5rem 1rem;
    width: 100%;
    border-top: 5px solid var(--color-primary-500);
    border-radius: .35rem;
    background-color: var(--color-white);
    text-align: center;
  }
  .headerChoice h1 {
    margin: 0;
  }
  
  .sectionChoice {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .choiceWrapper {
    border-radius: .35rem;
    overflow: hidden;
    background-color: var(--color-white);
    transform: scale(1);
    transition: 0.1s ease;
  }
  .choiceWrapper:hover {
    transform: scale(1.03);
    transition: 0.1s ease;
  }
  
  .backgroundBooklet {
    width: 100%;
    height: 2rem;
    background-size: cover;
  }
  
  .choiceBooklet {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
  }
  
  @media (max-width: 48rem) {
    .sectionChoice {
      grid-template-columns: 1fr;
    }
  }