.Checkbox{
    color: white !important;
}
.obs{
    display: flex;
    align-items: center;
    font-size: small;
    color: var(--color-primary-500);
}
.check > label {
    padding-top: 0.55rem
}
.check {
    display:inline-flex !important;
    padding-top: 0.5rem
}
.update{
    display: flex;
    align-items: center;
}
.table{
  padding: 1%;
}
.cellName{
    align-items: flex-start;
    color: var(--color-grey-500);
    line-height: 1.5;
    text-transform: uppercase;
    overflow-wrap: break-word;
}
.observation{
    display: inline-block;
    align-items: center;
    /* height: 45rem; */
}

.items{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 6px solid var(--color-primary-100);
}

.canvas {
    width: 100%;
    height: 100%;
}
.signatureContainer{
    margin-top: 5vh;
    display: flex;
  }

.signature{
    width: 20vh;
    height: 15vh;
    border: 1px solid black;
}

.FontAwesomeIcon{
    margin-left: 2vh;
}
.button{
  display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding-m);
    font-family: var(--font-primary-regular);
    font-size: var(--fs-m);
    text-transform: uppercase;
    border: 0;
    border-radius: 0.3rem;
    cursor: pointer;
}

