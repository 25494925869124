.paginationContainer {
  display: flex;
  margin: 0 0 1rem;
  padding: 0;
  list-style: none;
}

.paginationItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: var(--color-primary-500);
  background-color: transparent;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

.paginationItem:not(:last-child) {
  margin-right: 0.5rem;
}

.paginationItem.selected,
.paginationItem:not(.disabled):hover {
  background-color: var(--color-grey-200);
}

.paginationLabel {
  color: var(--color-primary-500);
}

.paginationItem.disabled {
  color: var(--color-grey-200);
  cursor: default;
}

.paginationInfos {
  margin-right: 1rem;
  margin-bottom: 1rem;
  color: var(--color-grey-400);
}

.paginationIndex {
  color: var(--color-primary-500);
  font-weight: 600;
}

.dots {
  cursor: default;
}

.dots:hover {
  background-color: transparent;
}

.paginationInput {
  width: 3rem;
  height: 2rem;
  padding: 0.5rem;
  border: 1px solid var(--color-grey-200);
  border-radius: 0.25rem;
  text-align: center;
}