.info {
  position: relative;
  min-height: 3rem;
  margin-bottom: 1.75rem;
  padding: 0.75rem 3.5rem 0.75rem 1.25rem;
  text-align: left;
  line-height: 1.5;
  border-radius: 0.35rem;
}

.infoSuccess {
  color: var(--color-success-600);
  background-color: var(--color-success-100);
}

.infoWarning {
  color: var(--color-white);
  background-color: var(--color-accent-500);
}

.infoError {
  color: var(--color-error-600);
  background-color: var(--color-error-100);
}

.dismiss {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  color: inherit;
  background: var(--color-white);
  border-radius: 50%;
}