.agenda_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-top: 45px;
}

.mois_container {
  flex: 1 1 auto;
  width: 30%;
  margin: 1%;
  padding: 1%;
  background-color: #ffffff;
  border-radius: 4px;
}

.mois_label {
  font-size: 2px;
  margin-bottom: 1vw;
  text-align: center;
  justify-content: center;
}

.taches_liste {
  list-style-type: none;
  padding: 0;
}

.mois_container ul {
  margin-bottom: 0.5vw;
  list-style-type: none;
}

.tache_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: -10%;
  border: #b4afaf 1px solid;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 5px;
  justify-content: space-between;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  cursor: pointer;
}

.tache_last {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: -10%;
  border: rgba(5, 190, 5, 0.767) 1px solid;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 5px;
  justify-content: space-between;
  box-shadow: 0px 4px 15px rgba(51, 51, 51, 0.2);
  transition: box-shadow 0.3s;
}

.tache_label {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.tache_date {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: rgb(94, 93, 93);
}

.tache_date p {
  margin: 0;
}

.tache_item {
  margin-bottom: 1vw;
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2vw;
  margin-top: -4.7rem;
}

.navigationButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin: 0 2vw;
  color: #555;
  transition: color 0.3s;
}

.todayButton {
  background-color: var(--color-primary-500);
  color: #fff;
  border: 1px solid #e0e0e0;
  font-size: 1rem;
  margin-right: 90%;
  margin-top: 0%;
  padding: 0.625rem 1.25rem;
  color: var(--color-white);
  background-color: var(--color-primary-500);
  border-radius: 1.875rem;
  text-decoration: none;
  width: max-content;
}

.navigationButton:hover {
  color: #000;
}

.year {
  font-size: 30px;
  font-weight: bold;
  margin-top: 23.904px;
}

.mois_container {
  margin-top: 0vw;

}

.mois {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 1vw;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
}

.legend {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2.5rem;
  justify-content: center;
}

.legend div {
  display: flex;
  padding: 0 10px;
}

.legend p{
  margin-top: -1px;
}

@media (max-width: 768px) {
  .mois_container {
    width: 45%;
    margin: 2.5%;
  }
}

@media (max-width: 480px) {
  .mois_container {
    width: 90%;
    margin: 5%;
  }
}
