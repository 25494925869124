/* .textarea{
    display: block;
    align-items: center;
    padding: var(--padding-s) var(--padding-m);
    font-family: var(--font-primary-regular);
    font-size: var(--fs-s);
    line-height: 2;
    color: var(--color-primary-500);
    background-color: var(--color-white);
    border: 1px solid #dadada;
    border-radius: 0.3rem;
} */

.Checkbox{
    color: white !important;
}

.obs{
    display: flex;
    align-items: center;
    font-size: small;
    color: var(--color-primary-500);
    
}

.check > label {
    padding-top: 0.55rem
}

.check {
    display:inline-flex !important;
    padding-top: 0.5rem
}

.update{
    display: flex;
    align-items: center;
}

.table{
    display: table;
}

.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 20rem;
  padding-top: 3rem;
  overflow-y: auto !important;;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 2rem;
}
.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 90rem;
}


.cellName{
    align-items: flex-start;
    color: var(--color-grey-500);
    line-height: 1.5;
    text-transform: uppercase;
    overflow-wrap: break-word;
}
.observation{
    display: inline-block;
    align-items: center;
    height: 45rem;
}

.items{
    display: flex;
}

@media (min-width: 768px) and (max-width: 1280px){
.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 20rem;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 3rem;
}
.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 70rem;
  padding-top: 3rem;
}
}

