.usersList{
    width: 80rem;
}

.usersList table th:nth-child(1),
.usersList table td:nth-child(1) {
  width: 5%;
}

.usersList table th:nth-child(2),
.usersList table td:nth-child(2) {
  width: 25%;
}

.usersList table th:nth-child(3),
.usersList table td:nth-child(3) {
  width: 10%;
}

.usersList table th:nth-child(4),
.usersList table td:nth-child(4) {
  width: 12%;
}

.usersList table th:nth-child(5),
.usersList table td:nth-child(5) {
  width: 12%;
}

.usersList table th:nth-child(6),
.usersList table td:nth-child(6) {
  width: 15%;
}

.usersList table th:nth-child(7),
.usersList table td:nth-child(7) {
  width: 15%;
}

.usersList table th:nth-child(8),
.usersList table td:nth-child(8) {
  width: 10%;
}

.usersList table th:nth-child(9),
.usersList table td:nth-child(9) {
  width: 5%;
}

.close{
  cursor: pointer;
  color: red;
  border-radius: 50%;
}