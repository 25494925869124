.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 5%;
  align-items: left;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 16%;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 12%;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 12%;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 12%;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 12%;
}

.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 9%;
}

.table table th:nth-child(8),
.table table td:nth-child(8) {
  width: 11%;
}

.table table th:nth-child(9),
.table table td:nth-child(9) {
  width: 10%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 80rem) {
.table  {
    width: 100%;
    font-size: 68%;
  }
}