.layout {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 4.375rem 1fr;
  min-height: 100vh;
  background-color: #fafafa;
  overflow-x: auto;
}

.aside {
  width: 16rem;
  grid-area: 1 / 1 / 4 / 2;
  background-color: var(--color-primary-500);
}

.asideCollapsed {
  width: 6.5rem;
}

.button {
  background-color: red;
}

.header {
  grid-area: 1 / 2 / 2 / 3;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.5rem 2.5rem;
  color: var(--color-white);
  background-color: var(--color-primary-500);
  background-size: cover;
}

.headerSecurite {
  background-image: url(../../assets/img/background_securite_top.png),
    linear-gradient(90deg, rgba(233, 22, 29, 1) 0%, rgba(253, 182, 12, 1) 100%);
  background-position: center center;
  background-size: cover;
}

.headerSanitaire {
  background-image: url(../../assets/img/background_sanitaire_top.png),
    linear-gradient(90deg, rgba(44, 40, 114, 1) 0%, rgba(72, 188, 237, 1) 100%);
  background-position: center center;
  background-size: cover;
}

.headerVehicule {
  background-image: url(../../assets/img/background_vehicule_top.png),
    linear-gradient(90deg, rgb(55, 181, 116) 0%, rgb(47, 149, 79) 100%);
  background-position: center center;
  background-size: cover;
}

.nameEstablishment {
  color: var(--color-white);
  text-transform: uppercase;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-xl);
  line-height: var(--fs-xl);
}

.picto {
  margin-right: var(--margin-l);
}

.main {
  grid-area: 2 / 2 / 3 / 3;
  padding: 1.75rem 2.5rem;
}

.table {
  overflow-x: hidden;
}

.footer {
  grid-area: 3 / 2 / 4 / 3;
  padding: 1.5rem;
  border-top: 1px solid var(--color-grey-200);
}

.menuBurger {
  display: none;
}

.menuToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

.menuToggle:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.menuToggle svg {
  color: rgba(255, 255, 255, 0.5);
}

.chevronLeft {
  opacity: 0.5;
}
.spacer {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  cursor: pointer;
}
.profiles {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 2rem;
  padding-right: 1rem;
  cursor: pointer;
}
.dropdownLinks {
  display: flex;
  color: var(--color-white) !important;
  align-items: center;
}

.pictos {
  margin-right: var(--margin-xl);
}
.pictos img {
  width: 10rem;
  height: 1.5rem;
}
.profile {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 1.75rem;
  border-left: 1px solid var(--color-white);
  cursor: pointer;
}

.userName {
  margin-right: 0.5rem;
  font-size: var(--fs-s);
  text-transform: uppercase;
}

.userPicture {
  border-radius: 50%;
}

.dropdown {
  position: absolute;
  top: 4.5rem;
  right: 2.5rem;
  min-width: 10rem;
  padding: 0.5rem 0;
  background-color: var(--color-white);
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  z-index: 2;
  animation-name: growIn;
  animation-duration: 0.2s;
}

.dropdownLink {
  display: flex;
  align-items: center;
  padding: 1.5rem 1.5rem;
  color: #3a3b45;
  background: transparent;
  text-decoration: none;
  font-family: var(--font-primary-regular);
  font-size: var(--fs-m);
  cursor: pointer;
}
.dropdownNotif {
  display: flex;
  align-items: center;
  color: #3a3b45;
  background: transparent;
  text-decoration: none;
  font-family: var(--font-primary-regular);
  font-size: var(--fs-m);
  line-height: 1.5;
  border: 0;
  cursor: pointer;
  max-width: 10rem;
  border-radius: 0.35rem;
  z-index: 2;
}

.dropdownLink:hover {
  background-color: #f8f9fc;
}

.dropdownLink > svg {
  width: 1rem;
  color: var(--color-grey-300);
}

.switchUser {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  padding: 0;
  color: var(--color-white);
  background-color: transparent;
  border-radius: 50%;
}

.switchUser:hover {
  background-color: var(--color-primary-600);
}

.divider {
  margin: 0.5rem 0;
  border: 0;
  border-top: 1px solid #eaecf4;
}

.close {
  color: var(--color-white);
  background-color: rgb(190, 28, 28);
  font-size: var(--fs-s);
  position: absolute;
  top: 0.5rem;
  border-radius: 1.875rem;
  cursor: pointer;
}

@media (max-width: 1177px) {
  .dropdownLinks {
    display: none;
  }
  .dropdownNotif {
    right: 30%;
  }
}

@media (max-width: 86rem) { /* 1366px en rem (85.375rem = 1366px) 1440px en rem (90rem = 1440px) */
  .header,
  .main {
    padding-inline: 1.5rem;
  }

  .aside {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 10;
    left: -16.5rem;
    transition: left 0.35s ease-in-out;
  }

  .asideVisible {
    left: 0;
  }

  .menuBurger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0;
    padding: 0;
    background-color: transparent;
    color: var(--color-white);
    font-size: 1.25rem;
    border: 0;
    transition: margin-left 0.35s ease-in-out;
  }

  .menuBurgerVisible {
    margin-left: 6.5rem;
  }

  .picto {
    display: none;
  }

  .nameEstablishment {
    max-width: 8.125rem;
    margin-left: 1rem;
    font-size: var(--fs-s);
    line-height: 1.2;
  }

  .profile {
    padding-left: 0;
    border-left: 0;
  }
  .profiles {
    padding-left: 0rem;
    border-left: 0;
  }
}
@media (max-width: 768px) {
  .dropdownLinks {
    display: none;
  }
  .dropdownNotif {
    right: 40%;
  }
}
@media (max-width: 400px) {
  .dropdownLinks {
    display: none;
  }

  .layout {
    display: contents;
  }
}

@keyframes growIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.version {
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  font-size: var(--fs-xs);
  color: var(--color-grey-300);
}

.refresh {
  margin-left: 5px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .version {
    position: sticky;
    text-align: center;
    margin-top: 5px;
  }
}

.notVersion {
  display: flex;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 5rem;
  background-color: var(--color-success-500);
  align-items: center;
  justify-content: center;
}

.updateVersion {
  cursor: pointer;
  font-size: var(--fs-s);
  height: 2.5rem;
  margin-left: 1rem;
  background-color: var(--color-grey-200);
  color: var(--color-success-500);
}

.containVersion {
  display: none;
  height: 5rem;
}

.divButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}

.buttonCancel {
  background-color: var(--color-error-500);
  color: var(--color-white);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.buttonRefresh {
  background-color: var(--color-success-500);
  color: var(--color-white);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.spanRefresh {
  color: var(--color-primary-500);
  font-weight: bold;
}
