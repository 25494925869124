.countBuildings{
  margin-right: 0.3rem;
}


.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 15%;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 13%;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 13%;
}
.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 13%;
}
.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 8%;
}
.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 7%;
  text-align: center;
}
.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 12%;
  text-align: center;
}
.table table th:nth-child(8),
.table table td:nth-child(8) {
  width: 9%;
  text-align: center;
}

.table {
  margin-top: var(--margin-m);
}

@media (max-width: 80rem) {

  .table {
    width: 100%;
    font-size: 68%;
  }
}

.close {
  padding: var(--padding-m) var(--padding-l);
  color: var(--color-white);
  background-color: rgb(190, 28, 28);
  font-size: var(--fs-s);
  font-weight: 700;
  left: 77rem;
  top: -1rem;
  position: relative;
  border-radius: 1.875rem;
  cursor: pointer;
}


.search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}