.switch {
  --switch-width: 4.875rem;
  --switch-height: 1.875rem;
  --switch-button-width: calc(var(--switch-width) / 3);
  position: relative;
  width: var(--switch-width);
  height: var(--switch-height);
}

.input {
  display: none;
}

.input + .label::after {
  content: "";
  position: absolute;
  top: 0; 
  left: 0; 
  width: var(--switch-button-width);
  height: var(--switch-height);
  z-index: 2;
}

.inputWrapper-ko .label::after {
  left: 0.125rem;
}

.inputWrapper-ko.checked ~ .inner {
  background-color: var(--color-error-600);
  transition: background-color 0.2s ease-in-out;
}

.inputWrapper-ko.checked ~ .inner::after {
  left: 0.125rem;
  transition: left 0.2s ease-in-out;
}

.inputWrapper-neutral .label::after {
  left: var(--switch-button-width);
}

.inputWrapper-neutral.checked ~ .inner {
  background-color: var(--color-primary-100);
  transition: background-color 0.2s ease-in-out;
}

.inputWrapper-neutral.checked ~ .inner::after {
  left: var(--switch-button-width);
  transition: left 0.2s ease-in-out;
}

.inputWrapper-ok .label::after {
  left: calc((var(--switch-button-width) * 2) - 0.125rem);
}

.inputWrapper-ok.checked ~ .inner {
  background-color: var(--color-success-600);
  transition: background-color 0.2s ease-in-out;
}

.inputWrapper-ok.checked ~ .inner::after {
  left: calc((var(--switch-button-width) * 2) - 0.125rem);
  transition: left 0.2s ease-in-out;
}

.inner {
  width: var(--switch-width);
  height: var(--switch-height);
  background-color: var(--color-primary-100);
  border-radius: 1rem;
  border: 1px solid #acb7ff;
}

.inner::after {
  content: "";
  position: absolute;
  top: 0.125rem; 
  left: 0;
  display: block;
  width: var(--switch-button-width);
  height: var(--switch-button-width);
  background-color: var(--color-white);
  border-radius: 50%;
  box-shadow: 0 1px 3px var(--color-grey-400);
  transition: left 0.2s ease-in-out;
}