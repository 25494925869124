.text {
  color: grey !important;
}

#sujet {
  width: 40vw;
}

Textarea:not(:focus) {
  color: grey;
}

.Textarea {
    display: block;
    width: 100%;
    padding: var(--padding-s) var(--padding-m);
    font-family: var(--font-primary-regular);
    font-size: var(--fs-s);
    line-height: 2;
    color: var(--color-primary-500) !important;
    background-color: var(--color-white);
    border: 1px solid #dadada;
    border-radius: 0.3rem;
  }