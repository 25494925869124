.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 8%;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 16%;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 15%;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 20%;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 9%;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 12%;
}

.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 9%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.close {
  padding: var(--padding-m) var(--padding-l);
  color: var(--color-white);
  background-color: rgb(190, 28, 28);
  font-size: var(--fs-s);
  font-weight: 700;
  left: 77rem;
  top: -1rem;
  position: relative;
  border-radius: 1.875rem;
  cursor: pointer;
}

.edit {
    border: none;
    color: var(--color-primary-500);
    background-color: rgba(240, 248, 255, 0);
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }