.addObsButton{
  margin-top: var(--margin-m)
}

.radio{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-top: var(--margin-m);
}

.radio__favorable label{
  color: var(--color-success-600) !important;
}

.radio__defavorable label{
  color: var(--color-error-600) !important;
}

.form_buttons{
  display: flex;
}

.form_delete button{
  --gradient1: rgb(141, 5, 5);
    --gradient2: rgb(145, 11, 34);
    flex-shrink: 0;
    color: var(--color-white);
    margin-top: 4vh;
    height: 44px;
    width: 44px;
    background: linear-gradient(0deg, var(--gradient1) 32%, var(--gradient2) 67%);
}

.form_buttons {
  justify-content: center;

}

@media (max-width: 1450px) {
  .form_delete Button {
    margin-top: 3.8vh;
  }
}

@media (max-width: 1200px) {
  .form_delete Button {
    margin-top: 3.6vh;
  }
}

@media (max-width: 960px) {
  .form_buttons{
    width: 100%;
  }
  .form_delete Button {
    margin-top: 0;
    width: 50px;
  }
  .form_delete {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 60rem) {
  .form_delete Button {
   padding: 1rem 5rem;
  }
}