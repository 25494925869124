.edit {
    border: none;
    color: var(--color-primary-500);
    background-color: rgba(240, 248, 255, 0);
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .table table th:nth-child(1),
  .table table td:nth-child(1) {
    width: 40%;
  }
  
  .table table th:nth-child(2),
  .table table td:nth-child(2) {
    width: 20%;
  }
  
  .table table th:nth-child(3),
  .table table td:nth-child(3) {
    width: 15%;
  }
  
  .table table th:nth-child(4),
  .table table td:nth-child(4) {
    width: 15%;
  }