.canvas {
    width: 90%;
    height: 90%;
}
.signatureContainer{
    margin-top: 5vh;
    display: flex;
  }

.signature{
    width: 20vh;
    height: 15vh;
    border: 1px solid black;
}
.FontAwesomeIcon{
    margin-left: 2vh;
}
.button{
  display: flex;
  --gradient1: rgb(6, 128, 57);
  --gradient2: rgb(8, 137, 42);
  background: linear-gradient(0deg, var(--gradient1) 32%, var(--gradient2) 67%) !important;
    align-items: center;
    justify-content: center;
    padding: var(--padding-m);
    background: var(--color-grey-200);
    font-family: var(--font-primary-regular);
    font-size: var(--fs-m);
    text-transform: uppercase;
    border: 0;
    border-radius: 0.3rem;
    cursor: pointer;
}
.regie{ 
  display: flex;
}
.regie label{
  align-items: center;
  display: flex;
}