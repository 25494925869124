.table, .table2 {
  margin-bottom: 20px;;
}
.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 8%;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 14%;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 15%;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 10%;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 12%;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 5%;
}

.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 6%;
}

.table table th:nth-child(8),
.table table td:nth-child(8) {
  width: 8%;
}

.table table th:nth-child(9),
.table table td:nth-child(9) {
  width: 8%;
}

.table2 table th:nth-child(4),
.table2 table td:nth-child(4) {
  width: 12%;
} 

.table2 table th:nth-child(5),
.table2 table td:nth-child(5) {
  width: 5%;
} 

.table2 table th:nth-child(6),
.table2 table td:nth-child(6) {
  width: 5%;
} 

.add {
  position: sticky;
  left: 170rem;
  justify-content: center;
  }
  @media (min-width: 300px) and (max-width: 768px) {
    .add > span {
      display: none;
    }
  }

  @media (max-width: 80rem) {
    .table {
      width: 100%;
      font-size: 68%;
    }
  }