.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 25%;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 15%;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 20%;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 15%;
  text-align: center;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 5%;
}
.nomaj {
  text-transform: lowercase !important;
}
.first {
  text-transform: capitalize !important;
}

.edit {
  border: none;
  color: var(--color-primary-500);
  background-color: rgba(240, 248, 255, 0);
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.add {
  position: sticky;
  left: 170rem;
  width: 10%;  
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .add > span {
    display: none;
  }
}

@media (max-width: 80rem) {
  .table {
    width: 100%;
    font-size: 80%;
  }
}
