.card{
  all: unset;
  border: 1px solid var(--color-primary-200);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.headerCard{
  background-color: var(--color-primary-100);
  word-break: break-word;
  white-space: break-spaces;
  /* border-top: 4px solid var(--color-accent-600); */
}
h3.headerCard{
  margin: 0;
  padding: var(--padding-m);
}
.red {
  /* background-color: var(--toastify-color-error); */
  background-color: var(--color-primary-100);
  word-break: break-word;
  white-space: break-spaces;
   border-top: 5px solid rgba(214, 41, 70, 1);
}

.green {
  /* background-color: rgba(44, 209, 44, 0.676); */
  background-color: var(--color-primary-100);
  word-break: break-word;
  white-space: break-spaces;
  border-top: 5px solid rgba(17,139,37);
}

.orange {
  background-color: var(--color-primary-100);
  word-break: break-word;
  white-space: break-spaces;
  border-top: 5px solid rgba(252,171,0);
}

h3.red{
  margin: 0;
  padding: var(--padding-m);
}
h3.green{
  margin: 0;
  padding: var(--padding-m);
}

.bodyCard{
  min-height: 100px;
}

@media (max-width: 768px) {
  .card {
    flex-direction: row;
  }

  .headerCard {
    flex: 1;
  }
  .bodyCard {
    flex: 2;
  }
}

@media (max-width: 400px){
  .card{
    width: 80vw;
  }
}