:root {
  /* COLORS */
  --color-primary-100: #f3f4ff;
  --color-primary-150: #E0E5F2;
  --color-primary-200: #d0d6ff;
  --color-primary-300: #acb7ff;
  --color-primary-400: #606ed0;
  --color-primary-450: #2e64a5;
  --color-primary-500: #28337e;
  --color-primary-600: #151d55;
  --color-primary-flash-200: #4BC0E4;
  --color-accent-400: #FABC26;
  --color-accent-500: #f38b2a;
  --color-accent-600: #f04a17;
  --color-success-100: #abf2e5;
  --color-success-500: #1cb79b;
  --color-success-600: #158974;
  --color-error-100: #f1a7b4;
  --color-error-500: #b11d38;
  --color-error-600: #841529;
  --color-white: #fff;
  --color-black: #000;
  --color-grey-100: #fafbff;
  --color-grey-200: #dcdee8;
  --color-grey-300: #afb1bf;
  --color-grey-400: #858796;
  --color-grey-500: #5d5f6d;
  --color-black-100: #000;
  --color-black-200: #00000024;

  /* Primary font */
  --font-primary-regular: 'Nunito', sans-serif;
  --font-primary-medium: 'Nunito', sans-serif;
  --font-primary-bold: 'Nunito', sans-serif;

  /* Font Sizes */
  --fs-xxs: 0.5rem;
  --fs-xs: 0.625rem;
  --fs-s: 0.8125rem;
  --fs-m: 1rem;
  --fs-l: 1.25rem;
  --fs-xl: 1.5rem;
  --fs-xxl: 2rem;

  /* Padding */
  --padding-xs: 0.25rem;
  --padding-s: 0.5rem;
  --padding-m: 1rem;
  --padding-l: 1.5rem;
  --padding-xl: 2rem;

  /* Margin */
  --margin-xs: 0.25rem;
  --margin-s: 0.5rem;
  --margin-m: 1rem;
  --margin-l: 1.5rem;
  --margin-xl: 2rem;

  --mobile-breakpoint: 768px;
  --tablet: 1024px;
  --desktop: 1280px;
}
body {
  font-size: 16px; /* set the rem basis so no variable here */
  font-family: var(--font-primary-regular);
  color: var(--color-black);
  padding: 0;
  margin: 0;
}

#pbError {
  display: flex !important;
  justify-content: center;
  text-align: center;
  width: 100%;
  z-index: -1000;
  position: absolute;
  top: 0;
  left: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  max-width: 71.25rem;
  padding-inline: var(--padding-l);
  margin-inline: auto;
}

img,
video {
  max-width: 100%;
  height: auto;
}

.hidden {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

h1,
.h1 {
  font-size: var(--fs-xxl);
  line-height: 1.25;
}

h2,
.h2 {
  font-size: var(--fs-xl);
  line-height: 1.2;
}

h3,
.h3 {
  font-size: var(--fs-l);
  line-height: 1.2;
}

.paragraph-1 {
  font-size: var(--fs-m);
  line-height: 1.5;
}

.paragraph-2 {
  font-size: var(--fs-s);
  line-height: 1.2;
}

.paragraph-3 {
  font-size: var(--fs-xs);
  line-height: 1.6;
}

caption,
figcaption,
.caption {
  font-size: var(--fs-xxs);
  line-height: 1.5;
}

.shadow-sm {
  box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.1);
}

.shadow-md {
  box-shadow: 0px 4px 15px rgba(51, 51, 51, 0.2);
}

.shadow-lg {
  box-shadow: 0px 2px 20px rgba(51, 51, 51, 0.25);
}

.shadow-xl {
  box-shadow: 0px 10px 20px 10px rgba(51, 51, 51, 0.1);
}

.shadow-xxl {
  box-shadow: 0px 10px 50px 10px rgba(51, 51, 51, 0.1);
}

.mb-20 {
  margin-bottom: var(--margin-m);
}

.pt-16 {
  padding-top: var(--padding-m);
}

.pt-24 {
  padding-top: var(--padding-l);
}

.error {
  display: flex;
  margin-block: var(--margin-s);
  color: var(--color-error-500);
  font-weight: 600;
}

.error::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23b11d38'%3E%3Cpath d='M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z'/%3E%3C/svg%3E");
  display: block;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin-top: 0.1875rem;
  margin-right: 0.375rem;
}

.paragraph {
  margin: 0 0 var(--margin-s);
}

.paragraph:last-child {
  margin-bottom: 0;
}

.info {
  margin-top: var(--margin-xs);
  color: var(--color-accent-500);
  font-size: var(--fs-s);
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.header {
  margin-bottom: 1rem;
}

.footer {
  margin-top: 2rem;
}

.title {
  margin: 0 0 1rem;
  color: var(--color-primary-500);
  font-weight: 600;
  text-transform: uppercase;
}

.titleHomePage{
  margin: 0 0 1rem;
  color: var(--color-primary-500);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.5rem;

}


.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: var(--color-white);
  padding: var(--padding-l) var(--padding-l) var(--padding-s);
  border-radius: 0.35rem;
}

.form_row {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  margin-top: var(--margin-m);
}

.form_row > *:not(.form_button) {
  width: 100%;
}

.form_group--fullwidth {
  grid-column: span 2;
  padding: var(--padding-xs);
}

.form_group--highlighted {
  padding: var(--padding-l);
  background-color: var(--color-primary-100);
  border-radius: 0.35rem;
}

.form_subfooter {
  grid-column: span 2;
  margin-block: var(--margin-m);
  color: var(--color-grey-400);
}

.form_footer {
  position: sticky;
  bottom: 0;
  grid-column: span 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: var(--padding-m);
  background-color: var(--color-white);
}

.form_infos {
  color: var(--color-grey-400);
}

.form_button {
  color: var(--color-primary-500);
  font-size: var(--fs-s);
  font-weight: 600;
}

.form_submit {
  --gradient1: rgba(39,49,128,1);
  --gradient2: rgba(57,87,195,1);
  flex-shrink: 0;
  color: var(--color-white);
  background: linear-gradient(0deg, var(--gradient1) 32%, var(--gradient2) 67%);
}

.form_submit:hover {
  background: linear-gradient(0deg, var(--gradient2) 32%, var(--gradient1) 67%);
}
.form_checkbox > label {
  display: table-caption;
  margin-top: 1rem;
}
.form_checkbox > input[type="checkbox"] {
  display: flex
}
.label {
  display: inline-block;
  margin-bottom: var(--margin-xs);
  color: var(--color-primary-500);
  font-size: var(--fs-s);
  text-transform: uppercase;
}

.link {
  display: inline-flex;
  align-items: center;
  padding: 0;
  color: var(--color-primary-500);
  background-color: transparent;
  font-family: var(--font-primary-regular);
  font-size: var(--fs-m);
  border: 0;
  cursor: pointer;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.link svg {
  height: 0.625em;
  margin-right: 0.5rem;
}

.modal_paragraph {
  margin: 0 0 1rem;
  color: var(--color-primary-500);
  font-size: var(--fs-m);
  text-align: center;
}

.modal_actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.modal_button {
  padding: var(--padding-m) var(--padding-l);
  color: var(--color-white);
  background-color: var(--color-grey-300);
  font-size: var(--fs-s);
  font-weight: 700;
  border-radius: 1.875rem;
  cursor: pointer;
}

.modal_button:not(:last-child) {
  margin-right: 1rem;
}

.modal_button--success {
  background-color: var(--color-error-500);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: end;
}

.action {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
  background-color: var(--color-white);
  border: 0;
  border-radius: 0.3125rem;
  cursor: pointer;
}

.action:hover {
  background-color: var(--color-grey-200);
}

.action:not(:last-child) {
  margin-right: 0.5rem;
}

.action svg {
  width: 1rem;
  height: 1rem;
  color: var(--color-primary-500);
}

.action-primary{
  background-color: var(--color-primary-500);;
}
.action-primary svg{
  color: var(--color-white);
}

.suppr svg {
  color: var(--color-error-500);
}

.supprimer {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.625rem 1.25rem;
  color: var(--color-white);
  background-color: var(--color-error-500);
  border-radius: 1.875rem;
  text-decoration: none;
  width: max-content;
}
.add {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.625rem 1.25rem;
  color: var(--color-white);
  background-color: var(--color-primary-500);
  border-radius: 1.875rem;
  text-decoration: none;
  width: max-content;
}

.add svg {
  margin-right: 0.625rem;
  color: var(--color-white);
}

.add_label {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
}

.tag {
  padding: var(--padding-s) var(--padding-m);
  color: var(--color-white);
  background-color: var(--color-primary-500);
  border-radius: 0.35rem;
}

.tag svg {
  margin-right: var(--margin-xs);
}

.tag--transparent {
  padding: 0;
  color: var(--color-primary-500);
  background-color: transparent;
}

.tag--success {
  color: var(--color-success-600) !important;
}

.tag--error {
  color: var(--color-error-500);
}

.no-margin{
  margin: 0!important;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.column_grid{
  display: grid;
}
.title-info{
  color: var(--color-grey-500);
  font-size: 1.25rem;
  font-weight: 600;
}
.loaderEtablissement {
  width: 3rem;
  height: 3rem;
  border: 2px solid var(--color-grey-200);
  border-top-color: var(--color-primary-500);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.loader {
  width: 1rem;
  height: 1rem;
  border: 2px solid var(--color-grey-200);
  border-top-color: var(--color-primary-500);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.title-info{
  color: var(--color-grey-500);
  font-size: 1.25rem;
  font-weight: 600;
}


.inputForm {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-primary-200);
}

.inputForm_control {
  position: relative;
  padding: 0.625rem 1.25rem 0.625rem 1rem;
}

.inputForm_control--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputForm_radioGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 16.25rem;
}

@media (min-width: 769px) {
  .input_column{
    display: none;
  }
}

.inputForm_label {
  margin-right: auto;
  color: var(--color-primary-500);
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.2;
}
.inputForm_labels:not(:first-child) {
  
  text-align: center;
}
.inputForm_labels {
  flex-shrink: 0;
  color: var(--color-primary-500);
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.2;
}

.inputForm_switch {
  width: 7.5rem;
}

.inputForm_input {
  width: 10rem;
}

.inputForm_date {
  width: 12.5rem;
}

.inputForm_volume {
  width: 10rem;
}

.inputForm_progress,
.inputForm_link {
  width: 15rem;
}

.inputForm_textarea {
  width: 17.5rem;
}

.inputForm_select {
  width: 20rem;
}

.inputForm_switch,
.inputForm_input,
.inputForm_date,
.inputForm_volume,
.inputForm_textarea,
.inputForm_select,
.inputForm_radio,
.inputForm_link {
  flex-shrink: 0;
}

.inputForm_input input,
.inputForm_date input,
.inputForm_volume input,
.inputForm_textarea textarea,
.inputForm_progress a,
.inputForm_link a {
  padding: 0.625rem 1.25rem;
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.5;
  border: 2px solid var(--color-primary-200);
  border-radius: 1.5625rem;
}

.inputForm_textarea textarea {
  resize: none;
}

.inputForm_link a {
  text-align: center;
  border-width: 1px;
  border-color: var(--color-primary-500);
}

.inputForm_link a,
.inputForm_link a:hover {
  text-decoration: none;
}

.inputForm_radio {
  position: relative;
}

.inputForm_radio + .inputForm_radio {
  margin-left: var(--margin-s);
}

.inputForm_radio input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.inputForm_radio label {
  margin-bottom: 0;
  padding: 0.625rem 1.25rem;
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.5;
  text-transform: lowercase;
  border: 2px solid var(--color-grey-200);
  border-radius: 1.5625rem;
  cursor: pointer;
}

.inputForm_radio input:checked + label {
  color: var(--color-white);
  background-color: var(--color-primary-600);
}

@media (max-width: 768px) {
  .inputControl{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .inputForm {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .inputForm_control {
    align-items: flex-end;
  }
  .inputForm_radioGroup {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 16.25rem;
  }

  .control{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .inputForm_inputs{
    width: 30%;
  }

  .titleHomePage{
    font-size: 1rem;
    display: flex;
  }

}

@media (max-width: 60rem) {
  .form_group {
    grid-column: span 2;
  }

  .form_row,
  .form_footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .form_infos {
    margin-bottom: 1rem;
  }
  .add{
   font-size: 0.8rem;
   width: max-content;
  }

  .supprimer{
    font-size: 0.8rem;
    width: max-content;
  
  }

}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.EditUploadFile {
  display: flex;
  justify-content: space-between;
}

.EditUploadFile span {
  margin-left: 20px;
  color: var(--color-primary-500);
  font-size: var(--fs-m);
  text-transform: none;
}


  
.accordion-item {
  background-color: #fff;
  border-radius: 0.25rem;
  display: inline-block;
}

.accordion-item th {
  padding: 0.5rem;
  color: var(--color-primary-500);
  line-height: 1.2;
  text-transform: uppercase;
  text-align: left;
}

.accordion-item td {
  padding: 2rem 0,5rem;
  line-height: 1.2;
  text-transform: capitalize;
}

.accordion-header {
  padding: 5px 10px;
  text-align: center;
}

.accordion-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  outline: none;
  padding: 0;
  text-align: left;
  color: var(--color-primary-500);
}

.accordion-button:focus {
  outline: none;
}

.accordion-collapse {
  display: none;
}

.accordion-collapse.collapse.show {
  display: block;
}

.accordion-body {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-body p {
  margin-bottom: 0;
}
