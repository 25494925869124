.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 5rem;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 20rem;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 10rem;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 8rem;
}

.search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}