.filter {
  display: flex;
  align-items: center;
}
.filterLabel {
  margin-right: var(--margin-m);
  color: var(--color-primary-500);
}
.user {
  text-align: center;
}
.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 5%;
}
.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 8%;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 6%;
}
.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 20%;
}
.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 16%;
}
.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 3%;
}

.table{
  width: 100%;
}

@media (max-width: 80rem) {
  .table {
    width: 100%;
    font-size: 65%;
  }
}

.notInputed{
  color: var(--color-error-500);
}

.search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.alertStyle {
  color: #b11d38;
}

.right {
  float: right;
  margin-left: 2px;
}