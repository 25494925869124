
.leftColumn{
  text-align: left;
}

.titleCard{
  font-weight: 800;
  font-size: var(--fs-l);
  line-height: var(--fs-xl);
  text-transform: uppercase;
  color: var(--color-primary-500);
}
.rightColumn{
  display: flex;
  justify-content: flex-end;
}

.picto{
  min-width: 70px;
  object-fit: scale-down;
}

.card{
  min-height: 110px;
  min-width: 240px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.middleColumn{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 100px;
  margin-left: 10px;
  margin-right: 10px;
}

.texteCard{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1rem;
}
