.subList {
  margin-top: 1rem;
  padding-block: 0.5rem;
  background-color: var(--color-white);
  border-radius: 0.35rem;
}

.subItem {
  padding: 0.5rem;
}

.subItem:hover {
  background-color: #eaecf4;
}

.logoItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.375rem;
  background-color: var(--color-white);
}

.link {
  display: flex;
  align-items: center;
  color: rgba(255,255,255,.8);
  text-decoration: none;
  cursor: pointer;
}

.link:not(.subLink):hover {
  color: var(--color-white);
}

.link:not(.subLink):hover .picto {
  opacity: 1;
}

.linkCenter {
  justify-content: center;
}

.linkPicto {
  margin-left: auto;
  opacity: 0.5;
}

.linkLabel {
  font-size: 0.8rem;
  text-transform: uppercase;
}

.subLink {
  color: var(--color-primary-500);
}

.picto {
  margin-right: 0.5rem;
  opacity: 0.8;
}

.dropdown {
  position: absolute;
  top: -1rem;
  left: 7.5rem;
  min-width: 14rem;
  box-shadow: 0px 2px 20px rgba(51, 51, 51, 0.25);
  z-index: 2;
  animation-name: growIn;
  animation-duration: 0.2s;
}

@keyframes growIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}