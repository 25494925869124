.label {
  display: inline-block;
  margin-bottom: var(--margin-xs);
  color: var(--color-primary-500);
  font-size: var(--fs-s);
  text-transform: uppercase;
}

.input {
  font-size: var(--fs-s);
  line-height: 2;
  color: var(--color-primary-500);
}


.select {
  color: var(--color-primary-500);
}