.button_operateur {
    width: 40px;
    height: 40px;
    margin-left: 0.5em;
}

.flex {
    display: flex;
    margin-bottom: 5px;
}

.button_grid {
    height: 44px;
    margin-left: 5px;
}

.div_miseensecu {
    background-color: var(--color-white);
    padding: var(--padding-l) var(--padding-l) var(--padding-s);
    border-radius: 0.35rem;
}

.big_div {
    display: grid;
    margin-bottom: 30px;
}

.div_radio {
    display: grid;
    margin-bottom: 10px;
}

.one_div_radio {
    grid-column: 1;
    grid-row: 1;
    width: 20vw;
}

.two_div_radio {
    grid-column: 3;
    grid-row: 1;
    width: 7vw;
}

.three_div_radio {
    grid-column: 4;
    grid-row: 1;
    width: 10vw;
}

.four_div_radio {
    grid-column: 5;
    grid-row: 1;
    width: 10vw;
}

.one_div_signature {
    grid-column: 1;
    grid-row: 1;
    width: 20vw;
}

.two_div_signature {
    grid-column: 3;
    grid-row: 1;
    width: 15vw;
}

.three_div_signature {
    grid-column: 4;
    grid-row: 1;
    width: 15vw;
}

.four_div_signature {
    grid-column: 5;
    grid-row: 1;
    width: 15vw;
}

.one_div_signature_autre {
    grid-column: 1;
    grid-row: 1;
    width: 10vw;
}

.two_div_signature_autre {
    grid-column: 2;
    grid-row: 1;
    width: 10vw;
}

.three_div_signature_autre {
    grid-column: 3;
    grid-row: 1;
    width: 15vw;
}

.four_div_signature_autre {
    grid-column: 4;
    grid-row: 1;
    width: 15vw;
}

.five_div_signature_autre {
    grid-column: 5;
    grid-row: 1;
    width: 15vw;
}

.pompier {
    color: red;
}

.margin_top {
    margin-top: 5px;
}

.canvas {
    width: 100%;
    height: 100%;
}

.signatureContainer{
    margin-top: 5vh;
    display: flex;
}

.signature{
    width: 10vh;
    height: 5vh;
    color: red;
}

.FontAwesomeIcon{
    margin-left: 2vh;
}

.button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(var(--padding-m) - 5px);
    font-family: var(--font-primary-regular);
    font-size: var(--fs-m);
    text-transform: uppercase;
    border: 0;
    border-radius: 0.3rem;
    cursor: pointer;
}

.buttonFinal{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding-m);
    font-family: var(--font-primary-regular);
    font-size: var(--fs-m);
    text-transform: uppercase;
    border: 0;
    border-radius: 0.3rem;
    cursor: pointer;
}

.column {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-right: 5px;
}

.between {
    display: flex;
    justify-content: space-between;
}

.footer {
    position: fixed;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px;
    width: calc(100% - 21rem);
    border-radius: 0.35rem 0.35rem 0 0;
    border: 2px solid rgba(57,87,195,1);
    border-bottom: none;
}

.div_footer {
    display: flex;
    justify-content: center;
}

.div_footer button {
    margin: 0 20px;
}

.marginBottom {
    margin-bottom: 70px;
}

.marginSign {
    margin-top: 24px;
}

@media (max-width: 1100px) {
    .footer {
        width: calc(100% - 18.5rem - 2.5rem);
    }
}

@media (max-width: 960px) {
    .hidden{
        display: none;
    }

    .footer {
        width: calc(100% - 11.5rem);
    }
}

@media (max-width: 768px) {
    .footer {
        width: calc(100% - 3rem);
    }
}

@media (max-width: 480px) {
    .div_footer button {
        margin: 0 10px;
    }

    .buttonFinal{
        padding: var(--padding-s);
        font-size: var(--fs-s);
    }
}


.close{
  background-color: var(--color-error-500);
  color: white;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  cursor: pointer;
}

.form_delete button{
  --gradient1: rgb(141, 5, 5);
  --gradient2: rgb(145, 11, 34);
  flex-shrink: 0;
  color: var(--color-white);
  margin-top: 3.6vh;
  height: 40px;
  width: 40px;
  background: linear-gradient(0deg, var(--gradient1) 32%, var(--gradient2) 67%);
}

@media (max-width: 1360px) {
  .form_delete Button {
    margin-top: 3.4vh;
  }
}

@media (max-width: 960px) {
  .form_buttons {
    width: 40px;
  }
  
  .form_delete Button {
    margin-top: 0;
    margin-left: 0.5em;
    width: 40px;
  }
}

.breakpage {
  page-break-after: always;
  scrollbar-color: transparent transparent;
}