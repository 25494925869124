
  .canvasSignature {
    width: 90%;
    height: 100%;
  }
  
  .sigContainer {
    width: 35vh;
    height: 30vh;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 10px;;
  }
  
  .sigPad {
    width: 100%;
    height: 100%;
  }

  .sigPad:hover {
    cursor: pointer;
  }
  .signatureContainer{
    margin-top: 5vh;
  }
.trimbtn{
  background-color: var(--color-primary-500) !important;
  color: white !important;
}

.clearbtn{
  background-color: var(--color-error-500) !important;
  color: white !important;
}

.img{
  background-color: rgba(135, 135, 135, 0.244);
  width: 50vh;
  height: 20vh;
}