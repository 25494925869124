/* .input{
    visibility: hidden;
} */

.photo{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 10vh
}
.img{
    width: auto !important;
    height: auto !important;
    object-fit: cover;
    margin-top: 5vh;
}