.headerChoice {
  position: relative;
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: .35rem;
  background-color: var(--color-white);
  text-align: center;
}

.headerChoice h1 {
  margin: 0;
}
.sectionChoice{
  padding: 1rem;
  background-color: var(--color-white);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.searchRow{
  align-self: end;
}

.sectionChoiceList{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 3rem;
  margin-top: var(--margin-m);
}


.security:before{
  content: '';
  position: absolute;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, rgba(233,22,29,1) 0%, rgba(253,182,12,1) 100%);
  top: -5px;
  left: 0;
}
.sanitary:before{
  content: '';
  position: absolute;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, rgba(44,40,114,1) 0%, rgba(72,188,237,1) 100%);
  top: -5px;
  left: 0;
}

.vehicule:before{
  content: '';
  position: absolute;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, rgb(55, 181, 116) 0%, rgb(47, 149, 79) 100%);
  top: -5px;
  left: 0;
}

.report{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  
}

.texteCard{
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5%;
}

.texteCards{
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5%;
  min-height: 60px;
}

.value{
  color: rgba(214, 41, 70, 1);
  margin-right: 5px;
  font-size: 1rem;
}
.values{
  font-size: 1rem;
}

.valueSanitaire{
  color: rgba(214, 41, 70, 1);
  margin-right: 5px;
  font-size: 1rem;
}

.chrono_ball {
  max-width: 5%;
  width: 5%;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  list-style: none !important;
}

.chrono_balls  {
  max-width: 5%;
  width: 5%;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  list-style: none !important;
}

.chrono_ball_prochaine{
  max-width: 5%;
  width: 5%;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  list-style: none !important;
}

.blockSecu {
  display: block;
  align-items: center;
}

.comisionSecu {
  display: flex;
  align-items: center;
  text-align: center;
}

.container {
  align-items: start;
  text-align: start;
}
.texteCard {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.picto {
  margin-right: 10px;
}
.CardText {
  display: flex;
  flex-direction: column;
}

.CardTexts {
  display: flex;
  flex-direction: column;
}

.reporttext {
  display: inline-flex;
}
.vertical {
  border-top: 1px solid green;
  width: 100%;
}

.commission{
  display: block;
}

.verticalDefavorable{
  border-top: 1px solid red;
  width: 100%;
}
.favorable{
  color: green;
}

.defavorable{
  color: red;
}

.filters {
  margin-block: var(--margin-xl);
}

.filters,
.filtersToComplete,
.filtersCompleted {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow-y: auto;
  margin-block: var(--margin-m);
}

.filter {
  margin-right: var(--margin-m);
  padding: 0.625rem 1.25rem;
  color: var(--color-primary-400);
  background-color: var(--color-white);
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.5;
  border: 1px solid var(--color-primary-400);
  border-radius: 1.875rem;
}

.filter:hover,
.filter:focus,
.filter.active {
  color: var(--color-white);
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-600);
}

.filter.completed {
  color: var(--color-success-600);
  background-color: var(--color-white);
  border-color: var(--color-success-600);
}

.filter.completed:hover,
.filter.completed:focus,
.filter.completed.active {
  color: var(--color-white);
  background-color: var(--color-success-600);
  border-color: var(--color-success-600);
}

.comisionSecus {
  display: flex;
  align-items: end;
  min-height: 70px;
  text-align: end;
}


.blockSecu{
  display: block;
  min-height: 90%;
  color: var(--color-primary-500);
}

.vehicles {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.vehicles p {
  margin-top: -1px;
  margin-left: 10px;
}