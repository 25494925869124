.buttons{
  position: relative;
  left: 45%
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0.875rem 1.25rem;
  border-bottom: 1px solid var(--color-primary-200);
}
  
.button {
  padding: 0.625rem 1.25rem;
  color: var(--color-white);
  background-color: var(--color-primary-500);
  font-weight: 900;
  text-transform: initial;
  border-radius: 2rem;
}

@media (max-width: 800px) {
  .buttons {
    left: 0;
  }
  
}