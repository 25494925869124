.warning{
    color: rgb(255, 255, 255);
    background-color: rgb(36, 81, 206);
    font-size: 15px;
    padding: 5px;
    margin-bottom: 2vh;
}
.cancel {
    background-color: var(--color-error-500);
    color: var(--color-white);
    flex-shrink: 0;
}

.form_button {
    display: inline-flex;
justify-content: space-between !important; 
}

@media (max-width: 60rem) {
    .form_button {
        flex-direction: column;
    align-items: flex-start;
    }
}
