.picto {
  width: 2rem;
}

.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 20%;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 8%;
  text-align: center;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 10%;
  text-align: center;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 11%;
  text-align: center;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 14%;
  text-align: center;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 10%;
  text-align: center;
}

.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 10%;
  text-align: center;
}

@media (max-width: 80rem) {
  .table {
    width: 100%;
    font-size: 68%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}