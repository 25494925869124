
.cardgeneral{
    grid-column: 1;
}
.book{
    grid-column: 2;
}
.suivieTravaux{
    grid-column: 3;
}
.reglementation{
    grid-column: 1;
}
.flamme{
    grid-column: 2; 
}

.Securite{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--margin-l)
}
.Vehicule{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--margin-l)
}
.Sanitaire {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--margin-l);
}

.card{
   padding-bottom: var(--margin-xs);
   margin: var(--margin-xs);
}

.cardSSI {
    padding-bottom: var(--margin-xs);
    margin: var(--margin-xs);
}

.ssi {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-columns: 20%;
    grid-gap: var(--margin-l);
}

.row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }