@media print {
    div {
        overflow: hidden;
    }
    aside {
        display: none;
    }
    .breakpage {
        page-break-after: always;
    }
    .printHidden {
        display: none;
    }
    ::placeholder {
        color: transparent;
    }
}

