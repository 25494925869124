
.headers {
  margin-bottom: 1rem;
}
.rows {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.mb20 {
  margin-bottom: var(--margin-m);
}

.links {
  display: inline-flex;
  align-items: center;
  padding: 0;
  color: var(--color-primary-500);
  background-color: transparent;
  font-family: var(--font-primary-regular);
  font-size: var(--fs-m);
  border: 0;
  cursor: pointer;
  text-decoration: none;
}

.links:hover {
  text-decoration: underline;
}
.titles {
  margin: 0 0 1rem;
  color: var(--color-primary-500);
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--color-primary-100);
  padding: 0.5rem 1rem;
}

.title {
  margin: 0 0 1rem;
  color: var(--color-primary-500);
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  width: 100%;
  text-align: center;
}


.adds {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.625rem 1.25rem;
  color: var(--color-white);
  background-color: var(--color-primary-500);
  border-radius: 1.875rem;
  text-decoration: none;
  width: max-content;
}

.adds svg {
  margin-right: 0.625rem;
  color: var(--color-white);
}
.actions {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
  background-color: var(--color-white);
  border: 0;
  border-radius: 0.3125rem;
  cursor: pointer;
}
.supprs svg {
  color: var(--color-error-500);
}

.actions:hover {
  background-color: var(--color-grey-200);
}

.actions:not(:last-child) {
  margin-right: 0.5rem;
}
.shadowSm {
  box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.1);
}
.actions svg {
  width: 1rem;
  height: 1rem;
  color: var(--color-primary-500);
}
.add_labels {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
}
.table {
  table-layout: fixed;
  width: 100%;
  background-color: black;
  background-color: var(--color-white);
  border-collapse: collapse;
  margin-bottom: 1rem;
}
.center {
  text-align: center;
}
.quantity {
  display: inline-block;
  margin-left: 0.5rem;
  text-align: left;
}
.pneumophila {
  display: inline-block;
  margin-left: 0.5rem;
  text-align: right;
}
.barre {
  display: inline-block;
  width: 100%;
}

.popup {
  background-color: rgb(240, 240, 240);
  color: rgb(3, 3, 3);
  width: 70%;
  height: 60%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  z-index: 1000;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.popupTitle {
  padding: 10px;
  font-size: large;
  align-items: center;
}

.popupButtons {
  background-color: rgb(240, 240, 240);
  color: rgb(3, 3, 3);
  width: 50%;
  height: 60%;
  margin: 0 auto;
  padding: 10px;
}
.add {
  position: sticky;
  left: 170rem;
  justify-content: center;
}

.barre {
  border-bottom: 1px solid rgba(21, 2, 105, 0.144);
  padding-bottom: 0.75rem;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.noResults {
  text-transform: lowercase;
}
.name {
  font-weight: normal;
  color: var(--color-primary-500);
}
@media (min-width: 768px) and (max-width: 1280px) {
  .name {
    margin-bottom: 3%;
    font-size: 0.75rem;
    width: 100%;
    text-align: left !important;
    border-bottom: 2px solid var(--color-primary-100);
  }
}

@media (min-width: 300px) and (max-width: 768px) {
  .wrapper {
    font-size: 0.75rem;
  }
  .sort {
    margin-left: 0.25rem;
  }
  .sort svg {
    width: 0.25rem;
  }
  .pagination {
    margin-top: 1rem;
  }
  .name {
    margin-bottom: 3%;
    font-size: 0.75rem;
    width: 100%;
    text-align: left !important;
    border-bottom: 2px solid var(--color-primary-100);
  }
  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }
  .accordion {
    box-sizing: content-box;
    width: 100%;
    background-color: rgba(229, 226, 226, 0.411);
    border-radius: 5px;
  }
  .accordion_button {
    font-size: 0.8rem;
    justify-content: center;
    color: var(--color-primary-200);
  }
  .add > span {
    display: none;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1.2rem;
  }
}

.filters {
  margin-block: var(--margin-xl);
}

.filters,
.filtersToComplete,
.filtersCompleted {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow-y: auto;
  margin-block: var(--margin-m);
}

.filter {
  margin-right: var(--margin-m);
  padding: 0.625rem 1.25rem;
  color: var(--color-primary-400);
  background-color: var(--color-white);
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.5;
  border: 1px solid var(--color-primary-400);
  border-radius: 1.875rem;
}

.filter:hover,
.filter:focus,
.filter.active {
  color: var(--color-white);
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-600);
}

.filter.completed {
  color: var(--color-success-600);
  background-color: var(--color-white);
  border-color: var(--color-success-600);
}

.filter.completed:hover,
.filter.completed:focus,
.filter.completed.active {
  color: var(--color-white);
  background-color: var(--color-success-600);
  border-color: var(--color-success-600);
}

@media (max-width: 80rem) {
  .controlTypeBloc,
  .filters,
  .filtersToComplete,
  .filtersCompleted {
    overflow-x: auto;
  }
}

.chart {
  width: 100%;
  height: 80%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  z-index: 1000;
  /* background-color: red; */
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.chart .chartTitle {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
}

/* analyse */


.headers {
  margin-bottom: 1rem;
}
.rows {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.mb20 {
  margin-bottom: var(--margin-m);
}

.links {
  display: inline-flex;
  align-items: center;
  padding: 0;
  color: var(--color-primary-500);
  background-color: transparent;
  font-family: var(--font-primary-regular);
  font-size: var(--fs-m);
  border: 0;
  cursor: pointer;
  text-decoration: none;
}

.links:hover {
  text-decoration: underline;
}
.titles {
  margin: 0 0 1rem;
  color: var(--color-primary-500);
  font-weight: 600;
  text-transform: uppercase;
}

.adds {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.625rem 1.25rem;
  color: var(--color-white);
  background-color: var(--color-primary-500);
  border-radius: 1.875rem;
  text-decoration: none;
  width: max-content;
}

.adds svg {
  margin-right: 0.625rem;
  color: var(--color-white);
}
.actions {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
  background-color: var(--color-white);
  border: 0;
  border-radius: 0.3125rem;
  cursor: pointer;
}
.supprs svg {
  color: var(--color-error-500);
}

.actions:hover {
  background-color: var(--color-grey-200);
}

.actions:not(:last-child) {
  margin-right: 0.5rem;
}
.shadowSm {
  box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.1);
}
.actions svg {
  width: 1rem;
  height: 1rem;
  color: var(--color-primary-500);
}
.add_labels {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
}

.cell {
  padding: 0.5rem;
  color: var(--color-primary-500);
  line-height: 2;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-primary-100);
  overflow-wrap: break-word;
}

.cellHeaderdoc{
  color: var(--color-grey-400);
  background-color: var(--color-grey-200);
  /* font-weight: 900; */
  line-height: 2;
  text-align: center;
  border-color: var(--color-grey-400);
}

.cellHeader {
  color: var(--color-grey-400);
  background-color: var(--color-grey-200);
  /* font-weight: 900; */
  line-height: 2;
  text-align: center;
  border-color: var(--color-grey-400);
}
.sort {
  display: inline-block;
  margin-left: 0.5rem;
}

.sort svg {
  width: 0.375rem;
}

.sorted {
  color: var(--color-primary-500);
}
.cellDoc {
  table-layout: fixed;
  width: 10%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.5;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-primary-100);
  overflow-wrap: break-word;
  color: var(--color-grey-400);
  background-color: var(--color-grey-100);
  /* font-weight: 900; */
  line-height: 1.2;
  text-align: left;
  border-color: var(--color-grey-200);
}
.celldoc {
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-primary-100);
  overflow-wrap: break-word;
  text-align: center;
  justify-content: center;
}

.cellaccordion {
  table-layout: fixed;
  margin: 0 auto;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-primary-100);;
  overflow-wrap: break-word;
  color: var(--color-grey-500);
  margin: 5px 0;
  text-align: center;
}
.cellAccordion {
  table-layout: fixed;
  width: 40%;
  justify-content: center;
  padding: auto;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-grey-400);
  overflow-wrap: break-word;
  /* font-weight: 900; */
  line-height: 1.2;
  text-align: left;
  color: var(--color-grey-400);
  background-color: var(--color-grey-200);

  border-color: var(--color-grey-400);
}
.sort {
  display: inline-block;
  margin-left: 0.5rem;
}

.sort svg {
  width: 0.375rem;
}

.sorted {
  color: var(--color-primary-500);
}

.red {
  color: red !important;
}
.green {
  color: var(--color-success-500) !important;
}
.orange {
  color: orange !important;
}

.blue {
  color: var(--color-primary-500);
}

.center {
  text-align: center;
}
.pneumophila {
  display: inline-block;
  margin-left: 0.5rem;
  text-align: right;
}
.barre {
  display: inline-block;
  width: 100%;
}
.popup {
  background-color: rgb(240, 240, 240);
  color: rgb(3, 3, 3);
  width: 70%;
  height: 60%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  z-index: 1000;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.popupTitle {
  padding: 10px;
  font-size: large;
  align-items: center;
}

.popupButtons {
  background-color: rgb(240, 240, 240);
  color: rgb(3, 3, 3);
  width: 50%;
  height: 60%;
  margin: 0 auto;
  padding: 10px;
}
.add {
  position: sticky;
  left: 170rem;
  justify-content: center;
}

.barre {
  border-bottom: 1px solid rgba(21, 2, 105, 0.144);
  padding-bottom: 0.75rem;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.content {
  margin-top: 10px;
}

.noResults {
  text-transform: lowercase;
}
.name {
  font-weight: normal;
  color: var(--color-primary-500);
}
@media (min-width: 768px) and (max-width: 1280px) {
  .name {
    margin-bottom: 3%;
    font-size: 0.75rem;
    width: 100%;
    text-align: left !important;
    border-bottom: 2px solid var(--color-primary-100);
  }
}

@media (min-width: 300px) and (max-width: 768px) {
  .wrapper {
    font-size: 0.75rem;
  }
  .sort {
    margin-left: 0.25rem;
  }
  .sort svg {
    width: 0.25rem;
  }
  .pagination {
    margin-top: 1rem;
  }
  .accordion {
    box-sizing: content-box;
    width: 100%;
    border-radius: 5px;
  }
  .accordion_button {
    font-size: 0.8rem;
    justify-content: center;
    color: var(--color-primary-500);
  }
  .add > span {
    display: none;
  }
  .cellaccordion {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.5;
    text-transform: uppercase;
    overflow-wrap: break-word;
    line-height: 1.2;
    text-align: left;
  }
  .celldoc {
    justify-content: center;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1.2rem;
  }
}

.tableBodyConforme {
  display: grid;
  grid-template-rows: 1fr;
}
.tableBodyConforme th:nth-child(1),
.tableBodyConforme td:nth-child(1) {
  width: 80%;
  padding-left: 5rem;
  padding-bottom: 10px;
}
.tableBodyConforme th:nth-child(2),
.tableBodyConforme td:nth-child(2) {
  width: 20%;
  padding-bottom: 10px;
}

.tableBodyPneumophila {
  table-layout: fixed;
  background-color: var(--color-white);
  border-collapse: collapse;
}

.tableBodyPneumophila th:nth-child(1),
.tableBodyPneumophila td:nth-child(1) {
  width: 50rem;
  align-content: center;
}

.tableBodyPneumophila th:nth-child(2),
.tableBodyPneumophila td:nth-child(2) {
  width: 20rem;
  align-items: center;
}

.tableBodyPneumophila th:nth-child(3),
.tableBodyPneumophila td:nth-child(3) {
  width: 30rem;
  align-items: center;
}

.icon {
  text-align: center !important;
}

.wrapper {
  overflow-x: auto;
  border-radius: 0.35rem;
}

.table{
  table-layout: fixed;
  width: 100%;
  background-color: var(--color-white);
  border-collapse: collapse;
}
.table .tableHeader th:nth-child(1),
.table .tableBody td:nth-child(1) {
  width: 15rem;
}

.inputsHistory table thead th:nth-child(1),
.inputsHistory table tbody td:nth-child(1) {
  width: 8%;
}
.inputsHistory table thead th:nth-child(2),
.inputsHistory table tbody td:nth-child(2) {
  width: 10%;
}
.inputsHistory table thead th:nth-child(3),
.inputsHistory table tbody td:nth-child(3) {
  width: 18%;
}
.inputsHistory table thead th:nth-child(4),
.inputsHistory table tbody td:nth-child(4) {
  width: 20%;
}

.table .tableHeader {
  background-color: var(--color-grey-100);
}

.table .tableBody {
  background-color: var(--color-white);
}

.table .tableHeader th:nth-child(1),
.table .tableBody td:nth-child(1) {
  width: 15%;
}

.table .tableHeader th:nth-child(2),
.table .tableBody td:nth-child(2) {
  width: 10%;
}

.table .tableHeader th:nth-child(3),
.table .tableBody td:nth-child(3) {
  width: 10%;
}

.table .tableHeader th:nth-child(4),
.table .tableBody td:nth-child(4) {
  width: 60%;
  align-items: center;
}


.accordions {
  margin: 0 !important;
}

.tables{
  width: 100%;
  background-color: var(--color-white);
  border-collapse: collapse;
}

.right {
  float: right;
  margin-left: 2px;
}