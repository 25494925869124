.progressBar {
  display: flex;
  align-items: center;
  min-width: 200px;
}

.label {
  flex-shrink: 0;
  margin-right: var(--margin-m);
  color: var(--color-grey-500);
  font-size: var(--fs-s);
  font-weight: 500;
}

.progress {
  height: 0.875rem;
  border-radius: 1.875rem;
}

.wrapper {
  width: 100%;
  background-color: var(--color-primary-100);
}

.inner {
  background-color: var(--color-error-600);
}

.inner[data-progress='inProgress'] {
  background-color: var(--color-accent-500);
}

.inner[data-progress='full'] {
  background-color: var(--color-success-600);
}