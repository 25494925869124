.tableVehicle table th:nth-child(1),
.tableVehicle table td:nth-child(1) {
  width: 10%;
}

.tableVehicle table th:nth-child(2),
.tableVehicle table td:nth-child(2) {
  width: 15%;
}

.tableVehicle table th:nth-child(3),
.tableVehicle table td:nth-child(3) {
  width: 10%;
}

.tableVehicle table th:nth-child(4),
.tableVehicle table td:nth-child(4) {
  width: 20%;
}

.tableVehicle table th:nth-child(5),
.tableVehicle table td:nth-child(5) {
  width: 10%;
}

.tableVehicle table th:nth-child(6),
.tableVehicle table td:nth-child(6) {
  width: 15%;
}

.tableVehicle table th:nth-child(7),
.tableVehicle table td:nth-child(7) {
  width: 8%;
}

.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 7%;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 10%;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 10%;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 5%;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 25%;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 8%;
}

.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 8%;
}

.table table th:nth-child(8),
.table table td:nth-child(8) {
  width: 9%;
  text-align: center;
}

.table table th:nth-child(9),
.table table td:nth-child(9) {
  width: 7%;
}

@media (max-width: 80rem) {
  .table {
    width: 100%;
    font-size: 68%;
  }

  .tableVehicle{
    width: 100%;
    font-size: 68%;
  }
}
.add {
  position: sticky;
  left: 170rem;
  width: 10%;  
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .add > span {
    display: none;
  }
}

.filters {
  margin-block: var(--margin-xl);
}

.filters,
.filtersToComplete,
.filtersCompleted {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow-y: auto;
  margin-block: var(--margin-m);
}

body {
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: var(--color-primary-600) rgb(255, 255, 255);   /* scroll thumb and track */ 
}

.filter {
  margin-right: var(--margin-m);
  padding: 0.625rem 1.25rem;
  color: var(--color-primary-400);
  background-color: var(--color-white);
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.5;
  border: 1px solid var(--color-primary-400);
  border-radius: 1.875rem;
}

.filter:hover,
.filter:focus,
.filter.active {
  color: var(--color-white);
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-600);
}

.filter.completed {
  color: var(--color-success-600);
  background-color: var(--color-white);
  border-color: var(--color-success-600);
}

.filter.completed:hover,
.filter.completed:focus,
.filter.completed.active {
  color: var(--color-white);
  background-color: var(--color-success-600);
  border-color: var(--color-success-600);
}

@media (max-width: 80rem) {
  .controlTypeBloc,
  .filters,
  .filtersToComplete,
  .filtersCompleted {
    overflow-x: auto;
  }
}

.delete {
  color: red;
}