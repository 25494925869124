.input {
  position: relative;
  top: 1px;
  margin: 0 var(--margin-s) 0 0;
  color: blue;
}

.label {
  display: inline-block;
  color: var(--color-primary-500);
  font-size: var(--fs-s);
  text-transform: uppercase;
  cursor: pointer;
}
