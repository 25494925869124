.tableau table th:nth-child(1),
.tableau table td:nth-child(1) {
  width: 10%;
}

.tableau table th:nth-child(2),
.tableau table td:nth-child(2) {
  width: 40%;
}

.tableau table th:nth-child(3),
.tableau table td:nth-child(3) {
  width: 15%;
}

.tableau table th:nth-child(4),
.tableau table td:nth-child(4) {
  width: 3%;
}

/* css pour booklet 3 */
.tableauVehicule table th:nth-child(1),
.tableauVehicule table td:nth-child(1) {
  width: 40%;
}

.tableauVehicule table th:nth-child(2),
.tableauVehicule table td:nth-child(2) {
  width: 8%;
}

.tableauVehicule table th:nth-child(3),
.tableauVehicule table td:nth-child(3) {
  width: 3%;
}

.filters {
  margin-block: var(--margin-xl);
}

.filters,
.filtersToComplete,
.filtersCompleted {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow-y: auto;
  margin-block: var(--margin-xs) var(--margin-xxs);
  margin-bottom: 0.5vh;
}

body {
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: var(--color-primary-600) rgb(255, 255, 255);   /* scroll thumb and track */ 
}

.filter {
  margin-right: var(--margin-m);
  padding: 0.625rem 1.25rem;
  color: var(--color-primary-400);
  background-color: var(--color-white);
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.5;
  border: 1px solid var(--color-primary-400);
  border-radius: 1.875rem;
}

.filter:hover,
.filter:focus,
.filter.active {
  color: var(--color-white);
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-600);
}

.filter.completed {
  color: var(--color-success-600);
  background-color: var(--color-white);
  border-color: var(--color-success-600);
}

.filter.completed:hover,
.filter.completed:focus,
.filter.completed.active {
  color: var(--color-white);
  background-color: var(--color-success-600);
  border-color: var(--color-success-600);
}

@media (max-width: 80rem) {
  .controlTypeBloc,
  .filters,
  .filtersToComplete,
  .filtersCompleted {
    overflow-x: auto;
  }

  .tableau {
    width: 100%;
    font-size: 68%;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}