.canvas {
    width: 100%;
    height: 100%;
}
.signatureContainer{
    margin-top: 5vh;
    display: flex;
  }

.signature{
    width: 20vh;
    height: 15vh;
    border: 1px solid black;
}
.FontAwesomeIcon{
    margin-left: 2vh;
}
.button{
  display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding-m);
    font-family: var(--font-primary-regular);
    font-size: var(--fs-m);
    text-transform: uppercase;
    border: 0;
    border-radius: 0.3rem;
    cursor: pointer;
}
.regie{ 
  display: flex;
}
.regie label{
  align-items: center;
  display: flex;
}

.close{
  background-color: var(--color-error-500);
  color: white;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  cursor: pointer;
}