.wrapper {
  overflow-x: auto;
  border-radius: 0.35rem;
}

.table {
  table-layout: fixed;
  width: 100%;
  background-color: var(--color-white);
  border-collapse: collapse;
}

.cell {
  padding: 0.5rem;
  color: var(--color-primary-500);
  line-height: 1.2;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-black-200);
  overflow-wrap: break-word;
}

.cellCorp{
  padding: 0.5rem;
  color: var(--color-primary-500);
  line-height: 1.2;
  text-transform: capitalize;
  border-bottom: 2px solid var(--color-black-200);
  overflow-wrap: break-word;
}

.cellHeader {
  color: var(--color-primary-500);
  background-color: var(--color-grey-200);
  font-weight: 900;
  line-height: 1.2;
  text-align: left;
  height: 3%;
  border-color: var(--color-grey-300);
}

.sort {
  display: inline-block;
  margin-left: 0.5rem;
}

.sort svg {
  width: 0.375rem;
}

.sorted {
  color: var(--color-primary-500);
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.noResults {
  text-transform: lowercase;
}


@media (max-width: 768px) {
  .table {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
}