.section {
  margin-top: var(--margin-l);
  background-color: var(--color-white);
  border-radius: 0.375rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-grey-400);
  font-weight: 900;
  line-height: 1.2;
  border-bottom: 1px solid var(--color-primary-200);
}

.col {
  padding: 0.875rem 1.25rem;
  font-size: var(--fs-m);
  font-weight: 600;
}

.col:first-child {
  margin-right: auto;
  color: var(--color-primary-500);
  font-size: var(--fs-l);
}

.col:not(:first-child) {
  flex-shrink: 0;
  text-align: center;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0.875rem 1.25rem;
}

.button {
  padding: 0.625rem 1.25rem;
  color: var(--color-white);
  background-color: var(--color-primary-500);
  font-weight: 900;
  text-transform: initial;
  border-radius: 2rem;
}

@media (max-width: 768px) {
  .header {
    display: none;
  }

  .col {
    padding: 0.625rem 1.25rem;
  }

  .col:first-child {
    margin-right: 0;
  }

  .footer {
    padding: 0.625rem 1.25rem;
  }
  
}