.form{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    margin-bottom: var(--margin-xl);
    padding: var(--padding-l) var(--padding-l) var(--padding-s);
    border: none !important;
    box-shadow: none !important;
}
.formRegistre{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    margin-bottom: var(--margin-xl);
    padding: var(--padding-l) var(--padding-l) var(--padding-s);
    border: none !important;
    box-shadow: none !important;
}
.form > div{
    margin-left: 3rem;
}
.search{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-s) var(--padding-xl);
}
.formRegistres {
    display: flex;
    align-items: left;
    justify-content: flex-end;
    padding: var(--padding-s) var(--padding-xl);
}

.tableTechnicien {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 3vh;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tableFormations {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 3vh;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tableRegie {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 3vh;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tableCommision{
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 3vh;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tableOrganisme{
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 3vh;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tableOtherDoc{
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 3vh;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tableTechnicien table th:nth-child(1),
.tableTechnicien table td:nth-child(1) {
  width: 15%;
}

.tableTechnicien table th:nth-child(2),
.tableTechnicien table td:nth-child(2) {
  width: 10%;
}

.tableTechnicien table th:nth-child(3),
.tableTechnicien table td:nth-child(3) {
  width: 5%;
}

.tableTechnicien table th:nth-child(4),
.tableTechnicien table td:nth-child(4) {
  width: 10%;
  text-align: center;
}

.tableTechnicien table th:nth-child(5),
.tableTechnicien table td:nth-child(5) {
  width: 8%;
  text-align: center;
}

.tableTechnicien table th:nth-child(6),
.tableTechnicien table td:nth-child(6) {
  width: 8%;
  text-align: center;
}

.tableTechnicien table th:nth-child(7),
.tableTechnicien table td:nth-child(7) {
  width: 5%;
  text-align: center;
}

.tableCommision table th:nth-child(1),
.tableCommision table td:nth-child(1) {
  width: 15%;
}

.tableCommision table th:nth-child(2),
.tableCommision table td:nth-child(2) {
  width: 5%;
}

.tableCommision table th:nth-child(3),
.tableCommision table td:nth-child(3) {
  width: 8%;
}

.tableCommision table th:nth-child(4),
.tableCommision table td:nth-child(4) {
  width: 5%;
  text-align: center;
}

.tableCommision table th:nth-child(5),
.tableCommision table td:nth-child(5) {
  width: 5%;
  text-align: center;
}

.tableCommision table th:nth-child(6),
.tableCommision table td:nth-child(6) {
  width: 6%;
  text-align: center;
}

.tableCommision table th:nth-child(7),
.tableCommision table td:nth-child(7) {
  width: 5%;
  text-align: center;
}

.tableOrganisme table th:nth-child(1),
.tableOrganisme table td:nth-child(1) {
  width: 15%;
}

.tableOrganisme table th:nth-child(2),
.tableOrganisme table td:nth-child(2) {
  width: 6%;
}

.tableOrganisme table th:nth-child(3),
.tableOrganisme table td:nth-child(3) {
  width: 8%;
}

.tableOrganisme table th:nth-child(4),
.tableOrganisme table td:nth-child(4) {
  width: 5%;
  text-align: center;
}

.tableOrganisme table th:nth-child(5),
.tableOrganisme table td:nth-child(5) {
  width: 5%;
  text-align: center;
}

.tableOrganisme table th:nth-child(6),
.tableOrganisme table td:nth-child(6) {
  width: 8%;
  text-align: center;
}

.tableOrganisme table th:nth-child(7),
.tableOrganisme table td:nth-child(7) {
  width: 5%;
  text-align: center;
}


.tableRegie table th:nth-child(1),
.tableRegie table td:nth-child(1) {
  width: 25%;
}

.tableRegie table th:nth-child(2),
.tableRegie table td:nth-child(2) {
  width: 20%;
}

.tableRegie table th:nth-child(3),
.tableRegie table td:nth-child(3) {
  width: 10%;
}

.tableRegie table th:nth-child(4),
.tableRegie table td:nth-child(4) {
  text-align: center;
  width: 10%;
}
.tableRegie table th:nth-child(5),
.tableRegie table td:nth-child(5) {
  text-align: center;
  width: 15%;
}
.tableRegie table th:nth-child(6),
.tableRegie table td:nth-child(6) {
  text-align: center;
  width: 15%;
}

.tableOtherDoc table th:nth-child(1),
.tableOtherDoc table td:nth-child(1) {
  width: 15%;
}

.tableOtherDoc table th:nth-child(2),
.tableOtherDoc table td:nth-child(2) {
  width: 10%;
}

.tableOtherDoc table th:nth-child(3),
.tableOtherDoc table td:nth-child(3) {
  width: 10%;
}

.tableOtherDoc table th:nth-child(4),
.tableOtherDoc table td:nth-child(4) {
  width: 10%;
}

.tableOtherDoc table th:nth-child(5),
.tableOtherDoc table td:nth-child(5) {
  width: 10%;
}

.tableOtherDoc table th:nth-child(6),
.tableOtherDoc table td:nth-child(6) {
  width: 10%;
  text-align: center;
}

.tableOtherDoc table th:nth-child(7),
.tableOtherDoc table td:nth-child(7) {
  width: 10%;
}



.filters {
  margin-block: var(--margin-xl);
}

.filters,
.filtersToComplete,
.filtersCompleted {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow-y: auto;
  margin-block: var(--margin-m);
}

.filter {
  margin-right: var(--margin-m);
  padding: 0.625rem 1.25rem;
  color: var(--color-primary-400);
  background-color: var(--color-white);
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.5;
  border: 1px solid var(--color-primary-400);
  border-radius: 1.875rem;
}

.filter:hover,
.filter:focus,
.filter.active {
  color: var(--color-white);
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-600);
}

.filter.completed {
  color: var(--color-success-600);
  background-color: var(--color-white);
  border-color: var(--color-success-600);
}

.filter.completed:hover,
.filter.completed:focus,
.filter.completed.active {
  color: var(--color-white);
  background-color: var(--color-success-600);
  border-color: var(--color-success-600);
}

@media (max-width: 80rem) {
  .controlTypeBloc,
  .filters,
  .filtersToComplete,
  .filtersCompleted {
    overflow-x: auto;
  }

  .tableTechnicien {
    width: 100%;
    font-size: 68%;
  }
  
  .tableFormations {
    width: 100%;
    font-size: 68%;
  }
  
  .tableRegie {
    width: 100%;
    font-size: 68%;
  }

  .tableCommision {
    width: 100%;
    font-size: 68%;
  }

  .tableOrganisme{
    width: 100%;
    font-size: 68%;
  }

  .tableOtherDoc{
    width: 100%;
    font-size: 68%;
  }

  .supp{
    display: flex;
    justify-content: flex-end;
    margin-right: 3rem;
  }
}