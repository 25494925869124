.card{
  min-height: 110px;
  min-width: 240px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.leftColumn{
  text-align: left;
}
.right{
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;

}
.titleCard{
  font-weight: 800;
  font-size: var(--fs-l);
  line-height: var(--fs-xl);
  text-transform: uppercase;
  color: var(--color-primary-500);
}
.textCard{
  display: flex;
  justify-content: baseline;
  color: var(--color-primary-500);

}
.picto{
  min-width: 70px;
  min-height: 100%;
  object-fit: scale-down;
  display: flex;
}
.numbersCard{
  color: var(--color-error-500);
  display: block;
  font-size: var(--fs-xl);
}

.disabled{
  background-color: var(--color-grey-200);
}

@media (min-width: 401px) and (max-width: 768px) {
  .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .leftColumn{
    grid-column: 1;
  }
  .right{
    display: grid;
    grid-column: 2;
    grid-template-columns: repeat(2, 1fr);
    justify-content: flex-end;
  }
  .numbersCard{
    grid-column: 1;
  }
  .picto{
    grid-column: 2;
  }

}

@media  screen and (max-width: 401px) {
 
  .right{
    display: flex;
    flex-direction: column-reverse;
  }
}
  /* .card{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .leftColumn{
    grid-column: 1;
  }
  .right{
    grid-column: 2;
  }

  
/* .leftColumn{
    text-align: left;
  }
  
  .titleCard{
    font-weight: 800;
    font-size: var(--fs-l);
    line-height: var(--fs-xl);
    text-transform: uppercase;
    color: var(--color-primary-500);
  }
  .textCard{
    display: flex;
    justify-content: baseline;
    color: var(--color-primary-500);

  }
  .rightColumn{
    display: flex;
    justify-content: flex-end;
  }
  
  .picto{
    min-width: 70px;
    min-height: 100%;
    object-fit: scale-down;
  }
  .numbersCard{
    color: var(--color-error-500);
    font-weight: 800;
    display: block;
    font-size: var(--fs-xl);
  }
  .card{
    min-height: 110px;
    min-width: 240px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  } */
