.SocietyList{
    width: 80rem;
}

.SocietyList table th:nth-child(1),
.SocietyList table td:nth-child(1) {
  width: 10%;
}

.SocietyList table th:nth-child(2),
.SocietyList table td:nth-child(2) {
  width: 15%;
}

.SocietyList table th:nth-child(3),
.SocietyList table td:nth-child(3) {
  width: 10%;
}

.SocietyList table th:nth-child(4),
.SocietyList table td:nth-child(4) {
  width: 10%;
}

.SocietyList table th:nth-child(5),
.SocietyList table td:nth-child(5) {
  width: 10%;
}

.SocietyList table th:nth-child(6),
.SocietyList table td:nth-child(6) {
  width: 10%;
}

.SocietyList table th:nth-child(7),
.SocietyList table td:nth-child(7) {
  width: 20%;
}

.SocietyList table th:nth-child(8),
.SocietyList table td:nth-child(8) {
  width: 10%;
}