.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.block {
  display: grid;
  overflow-x: auto;
}

@media  (max-width: 768px) {
  .spaceBetween {
    display: block;
  }
  
}