.controlTypeBloc {
  padding: var(--padding-m);
  border-radius: 8px;
  background-color: var(--color-primary-100);
  margin-bottom: var(--margin-m);
}

.controlTypeTitle {
  color: var(--color-primary-500);
  text-transform: uppercase;
}

.filters {
  margin-block: var(--margin-xl);
}

.filters,
.filtersToComplete,
.filtersCompleted {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow-y: auto;
  margin-block: var(--margin-m);
}

body {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: var(--color-primary-600) rgb(255, 255, 255); /* scroll thumb and track */
}

.filter {
  margin-right: var(--margin-m);
  padding: 0.625rem 1.25rem;
  color: var(--color-primary-400);
  background-color: var(--color-white);
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.5;
  border: 1px solid var(--color-primary-400);
  border-radius: 1.875rem;
}

.filter:hover,
.filter:focus,
.filter.active {
  color: var(--color-white);
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-600);
}

.filter.completed {
  color: var(--color-success-600);
  background-color: var(--color-white);
  border-color: var(--color-success-600);
}

.filter.completed:hover,
.filter.completed:focus,
.filter.completed.active {
  color: var(--color-white);
  background-color: var(--color-success-600);
  border-color: var(--color-success-600);
}
.add {
  position: sticky;
  left: 170rem;
  width: 10%;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .add > span {
    display: none;
  }
}

.filter.completeds {
  color: var(--color-success-600);
  background-color: var(--color-white);
  border-color: var(--color-success-600);
}

.filter.completeds:hover,
.filter.completeds:focus,
.filter.completeds.active {
  color: var(--color-white);
  background-color: var(--color-success-600);
  border-color: var(--color-success-600);
}
