.card {
  border:none;
  position: relative;
  padding: 1rem;
  width: 100%;
  border-radius: .35rem;
  background-color: var(--color-white);
  font-family: var(--font-primary-regular);
  /* text-align: center; */
  overflow: hidden;
  margin: 0;
}
button.card{
  cursor: pointer;
}


.card:before{
  content: '';
  position: absolute;
  width: 100%;
  height: 5px;
  background: var(--color-primary-500);
  top: 0;
  left: 0;
}

.security:before{
  background: linear-gradient(90deg, rgba(233,22,29,1) 0%, rgba(253,182,12,1) 100%);

}
.sanitary:before{
  background: linear-gradient(90deg, rgba(44,40,114,1) 0%, rgba(72,188,237,1) 100%);
}

.vehicule:before{
  background: linear-gradient(90deg, #007CA3 0%, #C9D100 100%);
}

.disabled {
  background: rgba(128, 128, 128, 0.452) !important;
  border:none;
  position: relative;
  padding: 1rem;
  width: 100%;
  border-radius: .35rem;
  font-family: var(--font-primary-regular);
  text-align: center;
  overflow: hidden;
  margin: 0;
}

.disabled:hover {
  cursor: not-allowed;
}
