.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 3vh;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tableFormations {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 3vh;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tableRegie {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 3vh;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.shadow {
  background-color: #fff;
}
.color {
  background: linear-gradient(
    0deg,
    rgba(0, 140, 28, 1) 32%,
    rgba(120, 173, 8, 1) 67%
  );
}
.table {
  margin-bottom: 20px;
}

.tableRegie {
  margin-bottom: 20px;
}
.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 15%;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 7%;
  text-align: center;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 7%;
  text-align: center;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 5%;
  text-align: center;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 5%;
  text-align: center;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 5%;
  text-align: center;
}

.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 6%;
  text-align: center;
}

.table table th:nth-child(8),
.table table td:nth-child(8) {
  width: 5%;
  text-align: center;
}

.tableRegie table th:nth-child(1),
.tableRegie table td:nth-child(1) {
  width: 25%;
}

.tableRegie table th:nth-child(2),
.tableRegie table td:nth-child(2) {
  width: 20%;
}

.tableRegie table th:nth-child(3),
.tableRegie table td:nth-child(3) {
  width: 10%;
}

.tableRegie table th:nth-child(4),
.tableRegie table td:nth-child(4) {
  text-align: center;
  width: 10%;
}
.tableRegie table th:nth-child(5),
.tableRegie table td:nth-child(5) {
  text-align: center;
  width: 15%;
}
.tableRegie table th:nth-child(6),
.tableRegie table td:nth-child(6) {
  text-align: center;
  width: 15%;
}

.tableFormations table th:nth-child(1),
.tableFormations table td:nth-child(1) {
  width: 10%;
}

.tableFormations table th:nth-child(2),
.tableFormations table td:nth-child(2) {
  width: 10%;
}

.tableFormations table th:nth-child(3),
.tableFormations table td:nth-child(3) {
  width: 10%;
}

.tableFormations table th:nth-child(4),
.tableFormations table td:nth-child(4) {
  width: 10%;
}

.tableFormations table th:nth-child(5),
.tableFormations table td:nth-child(5) {
  width: 10%;
  text-align: center;
}

.tableFormations table th:nth-child(6),
.tableFormations table td:nth-child(6) {
  width: 10%;
  text-align: center;
}

.tableFormations table th:nth-child(7),
.tableFormations table td:nth-child(7) {
  width: 10%;
}

.tableFormations table th:nth-child(8),
.tableFormations table td:nth-child(8) {
  width: 10%;
  text-align: center;
}

.controlTypeBloc {
  padding: var(--padding-m);
  border-radius: 8px;
  background-color: var(--color-primary-100);
  display: grid;
  margin-bottom: var(--margin-m);
}

.controlTypeTitle {
  color: var(--color-primary-500);
}

.filters {
  margin-block: var(--margin-xl);
}

.filters,
.filtersToComplete,
.filtersCompleted {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.filter {
  margin-right: var(--margin-m);
  padding: 0.625rem 1.25rem;
  color: var(--color-primary-400);
  background-color: var(--color-white);
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.5;
  border: 1px solid var(--color-primary-400);
  border-radius: 1.875rem;
}

.filter:hover,
.filter:focus,
.filter.active {
  color: var(--color-white);
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-600);
}

.filter.completed {
  color: var(--color-success-600);
  background-color: var(--color-white);
  border-color: var(--color-success-600);
}

.filter.completed:hover,
.filter.completed:focus,
.filter.completed.active {
  color: var(--color-white);
  background-color: var(--color-success-600);
  border-color: var(--color-success-600);
}
.filters,
.filtersToComplete,
.filtersCompleted {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow-y: auto;
  margin-block: var(--margin-m);
}
.controlTypeBloc,
.filters,
.filtersToComplete,
.filtersCompleted {
  overflow-x: auto;
}
@media (max-width: 80rem) {
  .table {
    font-size: 68%;
  }
  .tableFormations {
    font-size: 68%;
  }
  .tableRegie {
    font-size: 68%;
  }
}

/* mettren su le cote le plus droit */
.download {
  display: flex;
  justify-content: flex-end;
}

.add.disabled-btn {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.6;
}
