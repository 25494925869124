.page {
  min-height: 100vh;
  padding-block: var(--padding-l);
  background-color: var(--color-primary-500);
  background-image: url(../../assets/img/login-perso.png), url(../../assets/img/login-background.jpg);
  background-size: auto 55% , cover;
  background-repeat: no-repeat, no-repeat;
  background-position: right bottom, center center;
  text-align: center;
}

.title,
.subtitle {
  font-weight: 400;
  text-transform: uppercase;
}

.title {
  margin: 0 0 var(--margin-m);
  color: var(--color-primary-500);
  font-size: var(--fs-xl);
}

.subtitle {
  margin: var(--margin-m) 0 var(--margin-xl);
  color: var(--color-white);
}

.legend {
  margin-bottom: var(--margin-l);
  color: var(--color-grey-300);
  font-size: var(--fs-s);
  font-weight: 600;
}

.container {
  max-width: 33rem;
  margin-inline: auto;
  padding: 3rem;
  background-color: var(--color-white);
  border-radius: 0.35rem;
}

.form {
  text-align: left;
}

.formGroup {
  margin-bottom: var(--margin-m);
}

.submit {
  width: 100%;
}

.divider {
  margin-block: var(--margin-m);
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}

.logosAlt {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}