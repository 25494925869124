/* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
.gridTwoColumns{
  margin-top: var(--margin-m);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--margin-l)
}
.gridFourColumns{
  margin-top: var(--margin-m);
  display: grid;
  grid-template-columns:repeat(4, 1fr);
  grid-gap: var(--margin-l);
  /* height: 10rem; */
}

.gridThreeColumns{
  margin-top: var(--margin-m);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: var(--margin-l)
}


.cardSecurityTheme{
  background-color: var(--color-accent-400);
}
.cardSanitaryTheme{
  background-color: var(--color-primary-flash-200);
}
.cardBlueLightedTheme{
  background-color: var(--color-primary-150);
}
.cardBlueLightedTheme p {
  color: var(--color-primary-500);
}

.number
{
  font-weight: 700;
  font-size: var(--fs-xxl);
  line-height: var(--fs-xl);
  margin-top: 0.5rem;
}
.vehiculeTheme{
  background-color: #2C919c;
}
.vehiculeTheme p{
  color: var(--color-white);
}
.cardvehTheme{
  background-color: #b5d635;
}
.cardvehTheme p{
  color: var(--color-primary-500);
}
.cardLedger{
  background-color: var(--color-accent-600);
  color: var(--color-white);
}
.cardLedger p{
  color: var(--color-white);
}

.cardBook{
  background-color: var(--color-primary-450);
}
.cardBook p {
  color: var(--color-white);
}

@media (max-width: 80rem) {
  .gridTwoColumns {
    grid-template-columns: 1fr;
  }

  .gridFourColumns {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridThreeColumns {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 40rem) {
  .gridFourColumns {
    grid-template-columns: 1fr;
  }
}

.reporttext{
  font-size: var(--fs-m);
  line-height: var(--fs-l);
  color: var(--color-primary-500);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: var(--margin-m);
  list-style-type: none;
}

.reporttext::before{
  content: "•";
  color: red;
  font-weight: 800;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.sanitairesaisie{
  color: var(--color-primary-500);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: var(--margin-m);
  list-style-type: none;
}

.sanitairesaisie::before{
  content: "•";
  color: white;
  font-weight: 800;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.sanitaireanalyse{
  color: var(--color-primary-500);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: var(--margin-m);
  list-style-type: none;
}

.sanitaireanalyse::before{
  content: "•";
  color: red;
  font-weight: 800;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.ul {
  margin: 0;
  padding: 0;
  margin-left: 15px;
}

.ulsaisie {
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: var(--margin-m);
  list-style-type: none;
}

.latesecurityreportscount{
  color: var(--color-primary-500);
  font-weight: 600;
  align-items: start;
  margin-bottom: -100px;
  list-style-type: none;
}

/* .latesecurityreportscount::before{
  content: "•";
  color: red;
  font-weight: 800;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
} */

.ullatesecurityreportscount li::marker{
  color: red;
}

.cursorNotAllowed {
  cursor: not-allowed;
}

li {
  text-align: start;
}
