.input {
  position: relative;
  top: 1px;
  margin: 0 var(--margin-s) 0 0;
}

.label {
  display: inline-block;
  margin-bottom: var(--margin-xs);
  color: var(--color-primary-500);
  font-size: var(--fs-s);
  text-transform: uppercase;
}