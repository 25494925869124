.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 10%;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 13%;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 13%;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 13%;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 13%;
  text-align: center;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 12%;
  text-align: center;
}

.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 16%;
  text-align: center;
}

.table table th:nth-child(8),
.table table td:nth-child(8) {
  width: 12%;
  text-align: center;
}

.table table th:nth-child(9),
.table table td:nth-child(9) {
  width: 9%;
  text-align: center;
}
.table table th:nth-child(10),
.table table td:nth-child(10) {
  width: 12%;
  text-align: center;
}

.table table th:nth-child(11),
.table table td:nth-child(11) {
  width: 7%;
}
.filters {
  margin-block: var(--margin-xl);
}

.noadd{
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

.filters,
.filtersToComplete,
.filtersCompleted {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow-y: auto;
  margin-block: var(--margin-xs) var(--margin-xxs);
  margin-bottom: 0.5vh;
}


.filter {
  margin-right: var(--margin-m);
  padding: 0.625rem 1.25rem;
  color: var(--color-primary-400);
  background-color: var(--color-white);
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.5;
  border: 1px solid var(--color-primary-400);
  border-radius: 1.875rem;
}

.filter:hover,
.filter:focus,
.filter.active {
  color: var(--color-white);
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-600);
}

.filter.completed {
  color: var(--color-success-600);
  background-color: var(--color-white);
  border-color: var(--color-success-600);
}

.filter.completed:hover,
.filter.completed:focus,
.filter.completed.active {
  color: var(--color-white);
  background-color: var(--color-success-600);
  border-color: var(--color-success-600);
}


.table {
  width: 100%;
}

@media (max-width: 80rem) {
  .controlTypeBloc,
  .filters,
  .filtersToComplete,
  .filtersCompleted {
    overflow-x: auto;
  }

  .table {
    font-size: 68%;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}