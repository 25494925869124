.gridTwoColumns{
    margin-top: var(--margin-m);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--margin-l)
  }

.cardgeneral{
    grid-column: 1;
}
.book{
    grid-column: 2;
}
.suivieTravaux{
    grid-column: 3;
}
.reglementation{
    grid-column: 1;
}
.flamme{
    grid-column: 2;
}

.Securite{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--margin-l)
}
.Vehicule{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--margin-l)
}
.Sanitaire {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--margin-l)
}
@media screen and (max-width: 768px) {
    .gridTwoColumns{
        grid-template-columns: 1fr;
    }
    .cardgeneral{
        grid-column: 1;
    }
    .book{
        grid-column: 1;
    }
    .suivieTravaux{
        grid-column: 1;
    }
    .reglementation{
        grid-column: 1;
    }
    .flamme{
        grid-column: 1;
    }
    
}