.toggle {
  display: flex;
  align-items: center;
}

.toggleInput {
  display: inline-block;
  position: relative;
  width: 1.875rem;
  height: 1.25rem;
  user-select: none;
}

.input {
  display: none;
}

.label {
  position: absolute;
  inset: 0;
  background-color: var(--color-grey-300);
  border-radius: 0.9375rem;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.inner {
  position: relative;
  top: 0.125rem;
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: var(--color-white);
  border-radius: 50%;
  transform: translateX(0.125rem);
  transition: transform 0.2s linear;
}

.toggleInput .input:checked + .label {
  background-color: var(--color-primary-400);
}

.toggleInput .input:checked + .label .inner {
  transform: translateX(0.75rem);
}

.fakeLabel {
  margin-left: 0.625rem;
  color: var(--color-primary-600);
  font-weight: 600;
  line-height: 1.5;
}