.field {
  display: flex;
  align-items: center;
  margin-top: var(--margin-s);
}

.fakeInput {
  display: inline-flex;
  align-items: center;
  padding: var(--padding-s) var(--padding-m);
  background-color: var(--color-grey-200);
  color: var(--color-primary-500);
  font-family: var(--font-primary-regular);
  font-size: var(--fs-m);
  font-weight: 600;
  border-radius: 0.3125rem;
  cursor: pointer;
}

.input {
  display: none;
}

.value {
  margin-left: var(--margin-l);
  color: var(--color-primary-500);
  word-break: break-all;
}

.picto {
  margin-left: var(--margin-m);
}

.picto svg {
  color: var(--color-success-600);
}

.disabled {
  color: var(--color-grey-300);
}

.label {
  display: inline-block;
  margin-bottom: var(--margin-xs);
  color: var(--color-primary-500);
  font-size: var(--fs-s);
  text-transform: uppercase;
}

.field {
  border: 2px dashed #ccc;
  padding: 20px;
  transition: border-color 0.3s ease;
}

.otherField {
  display: flex;
  align-items: center;
  margin-top: var(--margin-s);
  padding: 20px;
}

.dragging {
  border-color: #007bff;
}