.adminGrid{
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.card{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title{
  text-align: left;
  color: var(--color-primary-500);
  margin: 0;
}

.titleNumber{
  font-weight: 400;
  font-size: var(--fs-l);
  line-height: var(--fs-xl);
  text-transform: uppercase;
}
.number{
  font-weight: 700;
  font-size: var(--fs-xxl);
  line-height: var(--fs-xl);
  margin-top: 1rem;
}

.pictoCard{
  width: 90px;
  object-fit: scale-down;
}

.fileDownload{
  color: var(--color-primary-500);
  font-size: calc(var(--fs-xxl)*2);
}

.gridDocs{
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
}

.topTitle{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.topPicto{
  margin: 0 0.75rem;
}