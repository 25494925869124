.remarque {
    display: flex;
    justify-content: flex-end;
    padding-right: 25px;
    border-bottom: 1px solid #d0d6ff;
}

.remarque p {
  max-width: 30rem;
}

.pop_up {
    background-color: white;
    position: fixed;
    top: 40px;
    left: 0;
    width: 40%;
    margin: 0 30%;
    border-radius: 0.35rem;
    box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.3);
    z-index: 1000000;
  }
  
  .div_button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid #dadada;
    padding: 20px;
    flex-wrap: wrap;
  }
  
  .title_popUp {
    color: #28337e;
    padding: 20px;
    border-bottom: 1px solid #dadada;
    margin: 0;
  }
  
  .textarea_popUp {
    padding: 20px 20px 30px;
  }
  
  .textarea_popUp textarea{
    resize:vertical;
    height: 8rem;
    max-height: 20rem;
  }
  
  .btn_popUp {
    padding: 0.5rem;
    margin: 0 10px;
    flex-shrink: 0;
    color: white;
  }
  
  .btn_blue {
    background: linear-gradient(0deg, rgba(39,49,128,1) 32%, rgba(57,87,195,1) 67%);
  }
  
  .btn_blue:hover {
    background: linear-gradient(0deg, rgba(57,87,195,1) 32%, rgba(39,49,128,1) 67%);
  }
  
  .btn_orange {
    background: linear-gradient(0deg, rgba(252,152,0,1) 32%, rgba(252,190,0,1) 67%);
  }
  
  .btn_orange:hover {
    background: linear-gradient(0deg, rgba(252,190,0,1) 32%, rgba(252,152,0,1) 67%);
  }
  
  .btn_red {
    background: linear-gradient(0deg, #8e1e33 32%, #d62946  67%);
  }
  
  .btn_red:hover {
    background: linear-gradient(0deg, #d62946  32%, #8e1e33 67%);
  }
  
  .back_popUp {
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
  }

  .icon {
    cursor: pointer;
    margin-top: 18px;
    margin-left: 10px;
  }
  
  @media (max-width: 1440px) {
    .pop_up {
      margin: 0 25%;
      width: 50%;
    }
  }
  
  @media (max-width: 1150px) {
    .pop_up {
      margin: 0 22%;
      width: 56%;
    }
  }
  
  @media (max-width: 1028px) {
    .div_button {
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-end;
      flex-wrap: wrap;
    }
  
    .btn_popUp {
      display: block;
      margin-bottom: 10px;
    }
  }
  
  @media (max-width: 768px) {
    .pop_up {
      margin: 0 15%;
      width: 70%;
    }
    .input{
      display: flex;
      justify-content: center;
      width: 40%;
    }
  }

