.wrapper {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
}

.table {
  max-width: 100%;
}

.table table {
  min-width: 40rem;
}