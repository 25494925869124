.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 5rem;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 10rem;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 15rem;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 15rem;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 10rem;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 10rem;
}