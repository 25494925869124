.commentaire {
    position: sticky;
    bottom: 0;
    grid-column: span 2;
    align-items: center;
    justify-content: space-between;
    padding-block: var(--padding-m);
    background-color: var(--color-white);
}
.society{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    background-color: var(--color-white);
    border-radius: var(--border-radius-m);
    box-shadow: var(--shadow-m);
}
.info{
    color : rgb(102, 101, 101);
}
.cancel {
    background-color: var(--color-error-500);
    color: var(--color-white);
    flex-shrink: 0;
}

.form_button {
    display: inline-flex;
justify-content: space-between !important; 
}

@media (max-width: 60rem) {
    .form_button {
        flex-direction: column;
    align-items: flex-start;
    }
}

.span {
    display: inline-flex;
    justify-content: space-between !important;
    margin-bottom: 0.3rem;
}