.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-m);
  background: var(--color-grey-200);
  font-family: var(--font-primary-regular);
  font-size: var(--fs-m);
  text-transform: uppercase;
  border: 0;
  border-radius: 0.3rem;
  cursor: pointer;
}

.loader {
  margin-right: var(--margin-m);
  border-color: var(--color-primary-500) !important;
  border-top-color: var(--color-white) !important;
}

.icon {
  margin-right: var(--margin-s);
}