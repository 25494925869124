.textarea {
  display: block;
  width: 100%;
  padding: var(--padding-s) var(--padding-m);
  font-family: var(--font-primary-regular);
  font-size: var(--fs-s);
  line-height: 2;
  color: var(--color-primary-500) !important;
  background-color: var(--color-white);
  border: 1px solid #dadada;
  border-radius: 0.3rem;
}

.label {
  display: inline-block;
  margin-bottom: var(--margin-xs);
  color: var(--color-primary-500);
  font-size: var(--fs-s);
  text-transform: uppercase;
}


@media (max-width: 768px) {
  .textarea {
    display: flex;
    justify-content: left;
  }
}