.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 4%;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 10%;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 6%;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 10%;
}
.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 10%;
  text-align: center;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 8%;
}

.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 4%;
  text-align: center;
}

.img {
  width: 5vh;
  height: 5vh;
  object-fit: cover;
  border-radius: 20%;
  zoom: 0.7;
}

.name {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--color-grey-500);
}

.commentaire {
  font-size: 1.5vh;
  margin-top: 1vh;
  font-weight: 900;
  margin-bottom: 1vh;
  text-align: center;
}

.divcommentaire {
  background-color: white;
  color: var(--color-primary-500);
  font-size: 1.25vh;
  border-radius: 1vh;
  padding: 1vh;
  margin-top: 1vh;
  border: solid 1px var(--color-primary-500);
}

.usercommentaire {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: larger;
}

.usercommentaire p {
  margin: 0;
}

@media (max-width: 80rem) {
  .table {
    font-size: 68%;
    width: 100%;
  }
}

.badge {
  position: relative;
  top: -8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 12px;
}

.noBadge {
  position: relative;
  top: -8px;
  background-color: var(--color-grey-300);
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 12px;
}

.search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.image {
  max-width: 10vh;
  max-height: 10vh;
}

.comment {
  font-size: 1.6vh;
  margin-top: 1vh;
  font-weight: 900;
  margin-bottom: 1vh;
}