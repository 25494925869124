.card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

.emailsUser {
    grid-column: 1;
}

.admin{
    grid-column: 2;
    grid-row: 1;
}

.recap_email{
    grid-column: 1/span 2;
}

.utilisateur_sanitaire, .admin_sanitaire{
    margin-top: 1rem;
    width: 100%;
    margin-bottom: 1rem;
}

.admin_securite, .utilisateur_securite{
    width: 100%;
}

.card{
    margin-top: 1rem;
}