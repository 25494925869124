.page {
  min-height: 100vh;
  padding-block: var(--padding-l);
  background-color: var(--color-primary-500);
  background-image: url(../../assets/img/login-perso.png), url(../../assets/img/login-background.jpg);
  background-size: auto 55% , cover;
  background-repeat: no-repeat, no-repeat;
  background-position: right bottom, center center;
  text-align: center;
}

.title,
.subtitle {
  font-weight: 400;
  text-transform: uppercase;
}

.title {
  margin: 0 0 var(--margin-m);
  color: var(--color-primary-500);
  font-size: var(--fs-xl);
}

.subtitle {
  margin: var(--margin-m) 0 var(--margin-xl);
  color: var(--color-white);
}

.login {
  max-width: 33rem;
  margin-inline: auto;
  padding: 3rem;
  background-color: var(--color-white);
  border-radius: 0.35rem;
}

.form {
  text-align: left;
}

.formGroup {
  margin-bottom: var(--margin-m);
}

.submit {
  width: 100%;
}

.error {
  margin-top: var(--margin-xs);
  color: var(--color-error-500);
  font-size: var(--fs-s);
}

.forget {
  margin-top: var(--margin-xs);
  text-align: right;
}

.link {
  margin: 0;
  padding: 0;
  color: var(--color-primary-500);
  background: transparent;
  font-size: var(--fs-s);
  border: 0;
  cursor: pointer;
}

.divider {
  margin-block: var(--margin-m);
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}

.logosAlt {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.input {
  display: block;
  width: 100%;
  padding: var(--padding-s) var(--padding-m);
  font-family: var(--font-primary-regular);
  font-size: var(--fs-s);
  line-height: 2;
  color: var(--color-primary-500);
  background-color: var(--color-white);
  border: 1px solid #dadada;
  border-radius: 0.3rem;
}

input:disabled {
  color: hsl(0, 0%, 60%);
  background-color: hsl(0, 0%, 95%);
}

.label {
  display: inline-block;
  margin-bottom: var(--margin-xs);
  color: var(--color-primary-500);
  font-size: var(--fs-s);
  text-transform: uppercase;
}

.version {
  display: flex;
  color: grey;
  font-size: var(--fs-xs);
  justify-content: flex-end;
  margin: 30px -30px -30px 30px;
}

.formGroup {
  position: relative;
}

.togglePassword {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem; /* Taille de l'icône de l'œil */
  padding: 0;
  opacity: 0.75;
  color: var(--color-primary-500);
}
