
.mdpregle{
    color : red !important;
}

.rights {
    margin-left: 5px;
}

.rightsBlock{
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

@media screen and (max-width: 768px) {
    .rightsBlock{
        grid-template-columns: 50% 50%;
    }
}

@media screen and (max-width: 480px) {
    .rightsBlock{
        grid-template-columns: 100%;
    }
}