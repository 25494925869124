.form{
    color: var(--color-primary-500);
}

.form_group{
    margin-top: 1rem;
    background-color: white;
    display: flex;
}

.form_groups{
    display: block;
}
.bouton{
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    background-color: var(--color-white);
    padding: var(--padding-l) var(--padding-l) var(--padding-s);
    border-radius: 0.35rem;
    justify-content: space-between;
}

.gras{
    font-weight: bold;
}