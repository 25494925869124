.topTitleWrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.alignedCenter{
  align-items: center;
  /* justify-content: center; */
}

.topTitle{
  display: flex;
  text-align: center !important;
}
.topPicto{
  min-width: 40px;
  margin: 0 0.75rem;
}

.rightUserSecurity{
  display: flex;
  align-items: end;
}

.lastCommissionWrapper{
  display: flex;
  flex-direction: column;
}
.lastCommission{
  margin: 0;
}

.evaluation{
  margin-left: var(--margin-m);
  padding: var(--padding-s);
  border-radius: 8px;
  background: var(--color-success-600);
  color: var(--color-white);
  align-self: center;
}
.deevaluation{
  margin-left: var(--margin-m);
  padding: var(--padding-s);
  border-radius: 8px;
  background: var(--color-error-600);
  color: var(--color-white);
  align-self: center;
}

.picto {
  width: 50px;
}

.btnVideo {
  border: none;
  background-color: transparent;
}

.btnVideo:hover {
  cursor: pointer;
}