.cancel {
    padding: var(--padding-m) var(--padding-l);
    color: var(--color-white);
    background-color: rgb(14, 47, 194);
    font-size: var(--fs-s);
    font-weight: 700;
    position: relative;
    top : 2rem;
    border-radius: 1.875rem;
    cursor: pointer;
}
.header {
    margin-bottom: 1rem;
    font-size: 3.5rem;
    font-weight: 700;
}

.text {
    color: rgb(0, 0, 0);
    border: 1px solid rgba(124, 122, 122, 0.308);
    border-radius: 1.875rem;
    height: max-content;
    overflow: auto;
}
.message{
    padding : var(--padding-m) var(--padding-l);
    color: rgb(0, 0, 0);
    font-size: 1.50rem;
    width: 100%;
    height: 25vh;
    border-radius: 1.875rem;
}
.titre {
    margin: 0 0 1rem;
    color: rgba(6, 4, 88, 0.767);
    text-decoration: underline;
    font-weight: 700;
    font-size: 2rem;
    padding: 1.5rem 0rem 1em 0rem;
}

.button {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 2.5rem;
    padding: 0 1rem;
  }

.but {
    position: sticky;
    top : 2rem;
  }

.close {
    padding: var(--padding-m) var(--padding-l);
    color: var(--color-white);
    background-color: rgb(190, 28, 28);
    font-size: var(--fs-s);
    border-radius: 1.875rem;
    cursor: pointer;
}

.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 5rem;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 5rem;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 2rem;
}
