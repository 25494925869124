.label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 0;
    /* border-bottom: 1px solid #e0e0e0; */
    cursor: pointer;
}

.control{
    background-color: red;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    
}