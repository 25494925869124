.video_popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.video_popup_content {
    width: 80%;
    height: 80%;
    position: relative;
}

iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.close_button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: white;
    cursor: pointer;
}
