.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--color-error-100);
    border-radius: 5px;
    color: var(--color-error-600);
    margin-top: 10px;
}

.links {
    display: inline-flex;
    align-items: center;
    padding: 0;
    color: var(--color-primary-500);
    background-color: transparent;
    font-family: var(--font-primary-regular);
    font-size: var(--fs-m);
    border: 0;
    cursor: pointer;
    text-decoration: none;
}

.links:hover {
    text-decoration: underline;
}

.button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1rem;
    font-family: var(--font-primary-regular);
}
