.canvas {
  width: 100%;
  height: 100%;
}
.signatureContainer {
  margin-top: 5vh;
  display: flex;
}
.modal {
  font-weight: bold;
}

.signature {
  width: 10vh;
  height: 5vh;
  color: red;
}

.table {
  width: 100%;
}

.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 10%;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 17%;
}
.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 20%;
}
.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 25%;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 15%;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 22%;
}

.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 20%;
}

.table table th:nth-child(8),
.table table td:nth-child(8) {
  width: 10%;
}

.actions {
  display: table-caption;
  justify-content: end;
  align-items: flex-start;
}

.filters {
  margin-block: var(--margin-xl);
}

.filters,
.filtersToComplete,
.filtersCompleted {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.filter {
  margin-right: var(--margin-m);
  padding: 0.625rem 1.25rem;
  color: var(--color-primary-400);
  background-color: var(--color-white);
  font-size: var(--fs-m);
  font-weight: 600;
  line-height: 1.5;
  border: 1px solid var(--color-primary-400);
  border-radius: 1.875rem;
}

.filter:hover,
.filter:focus,
.filter.active {
  color: var(--color-white);
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-600);
}

.filter.completed {
  color: var(--color-success-600);
  background-color: var(--color-white);
  border-color: var(--color-success-600);
}

.filter.completed:hover,
.filter.completed:focus,
.filter.completed.active {
  color: var(--color-white);
  background-color: var(--color-success-600);
  border-color: var(--color-success-600);
}

.filters,
.filtersToComplete,
.filtersCompleted {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow-y: auto;
  margin-block: var(--margin-m);
}
.controlTypeBloc,
.filters,
.filtersToComplete,
.filtersCompleted {
  overflow-x: auto;
}

@media (max-width: 80rem) {
  .table {
    width: 100%;
    font-size: 65%;
  }
}
