.textarea {
  grid-column: span 2;
  justify-content: space-between;
}
.titre{
  position: static;
  grid-column: span 2;
  align-items: center;
  justify-content: space-between;
  padding-block: var(--padding-m);
  background-color: var(--color-white);
}
.textarea Textarea{
    width: 100%;
    padding: var(--padding-s) var(--padding-m);
    font-family: var(--font-primary-regular);
    font-size: var(--fs-s);
    line-height: 2;
    color: var(--color-primary-500);
    background-color: var(--color-white);
    border: 1px solid #dadada;
    border-radius: 0.3rem;
}

.form {
  display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: var(--color-white);
    padding: var(--padding-l) var(--padding-l) var(--padding-s);
    border-radius: 0.35rem;
}