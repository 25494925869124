  
  .shadowSm {
    box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.1);
  }
  
  .cell {
    padding: 0.5rem;
    color: var(--color-primary-500);
    line-height: 1.2;
    text-transform: uppercase;
   border-bottom: 2px solid var(--color-black-200);
    overflow-wrap: break-word;
  }
  
  .cellHeaderdoc{
    color: var(--color-primary-500);
    background-color: var(--color-grey-200);
    /* font-weight: 900; */
    line-height: 2;
    text-align: center;
    border-color: var(--color-grey-400);
  }
  
  .cellHeader {
    color: var(--color-primary-500);
    background-color: var(--color-grey-200);
    /* font-weight: 900; */
    line-height: 2;
    text-align: center;
    border-color: var(--color-grey-400);
  }

  .celldoc {
    text-transform: uppercase;
   border-bottom: 2px solid var(--color-black-200);
    overflow-wrap: break-word;
    text-align: center;
    justify-content: center;
  }
  
  .cellaccordion {
    table-layout: fixed;
    margin: 0 auto;
    text-transform: uppercase;
   border-bottom: 2px solid var(--color-black-200);
    overflow-wrap: break-word;
    color: var(--color-grey-200);
    margin: 5px 0;
    text-align: center;
  }
  .cellAccordion {
    table-layout: fixed;
    width: 40%;
    justify-content: center;
    padding: auto;
    text-transform: uppercase;
    border-bottom: 2px solid var(--color-grey-400);
    overflow-wrap: break-word;
    /* font-weight: 900; */
    line-height: 1.2;
    text-align: left;
    color: var(--color-grey-400);
    background-color: var(--color-grey-200);
  
    border-color: var(--color-grey-400);
  }
  .sort {
    display: inline-block;
    margin-left: 0.5rem;
  }
  
  .sort svg {
    width: 0.375rem;
  }
  
  .sorted {
    color: var(--color-primary-500);
  }
  
  .red {
    color: red !important;
  
  }
  .green {
    color: var(--color-success-500) !important;
  }
  .orange {
    color: orange !important;
  }
  
  .blue {
    color: var(--color-primary-500);
  }
  
  .center {
    text-align: center;
  }
  
  .pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  .name {
    font-weight: normal;
    color: var(--color-primary-500);
  }

  @media  (max-width: 1280px) {
    .name {
      margin-bottom: 3%;
      font-size: 0.75rem;
      width: 100%;
      text-align: left !important;
    }
  }
  
  @media (min-width: 300px) and (max-width: 768px) {
    .wrapper {
      font-size: 0.75rem;
    }
    .sort {
      margin-left: 0.25rem;
    }
    .sort svg {
      width: 0.25rem;
    }
    .pagination {
      margin-top: 1rem;
    }
    .accordion {
      box-sizing: content-box;
      width: 100%;
      border-radius: 5px;
    }
    .celldoc {
      justify-content: center;
    }
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 1.2rem;
    }
  }
  
  .noConforme {
    color: red;
    width: 150%;
  }
  
  .tableBodyConforme {
    table-layout: fixed;
    background-color: var(--color-white);
    border-collapse: collapse;
  }
  .tableBodyConforme th:nth-child(1),
  .tableBodyConforme td:nth-child(1) {
    width: 50%;
    padding-bottom: 10px;
  }
  .tableBodyConforme th:nth-child(2),
  .tableBodyConforme td:nth-child(2) {
    width: 20%;
    padding-bottom: 10px;
  }
  
  .tableBodyPneumophila {
    table-layout: fixed;
    background-color: var(--color-white);
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
    align-content: center;
  }
  
  .tableBodyPneumophila th:nth-child(1),
  .tableBodyPneumophila td:nth-child(1) {
    width: 30rem;
    align-content: left;
  }
  
  .tableBodyPneumophila th:nth-child(2),
  .tableBodyPneumophila td:nth-child(2) {
    width: 20%;
    align-items: left;
  }
  
  .tableBodyPneumophila th:nth-child(3),
  .tableBodyPneumophila td:nth-child(3) {
    width: 40%;
    align-items: left;
  }
  
  .icon {
    text-align: center !important;
  }
  
  .wrapper {
    overflow-x: auto;
    border-radius: 0.35rem;
  }
  
  .table{
    table-layout: fixed;
    width: 100%;
    background-color: var(--color-white);
    border-collapse: collapse;
  }
  .table .tableHeader th:nth-child(1),
  .table .tableBody td:nth-child(1) {
    width: 15rem;
  }
  
  .table .tableHeader {
    background-color: var(--color-grey-200);
  }
  
  .table .tableBody {
    background-color: var(--color-white);
  }
  
  .table .tableHeader th:nth-child(1),
  .table .tableBody td:nth-child(1) {
    width: 15%;
  }
  
  .table .tableHeader th:nth-child(2),
  .table .tableBody td:nth-child(2) {
    width: 10%;
  }
  
  .table .tableHeader th:nth-child(3),
  .table .tableBody td:nth-child(3) {
    width: 10%;
  }
  
  .table .tableHeader th:nth-child(4),
  .table .tableBody td:nth-child(4) {
    width: 60%;
    align-items: center;
  }
  .table .tableHeader th:nth-child(5),
  .table .tableBody td:nth-child(5) {
    width: 5%;
  }
  
  .accordions {
    margin: 0 !important;
  }
  
  .accordion-item td {
    text-align: center;
  }
  
  .accordion-item th {
    text-align: center;
  }