.red {
    color: red !important;
}
.strong {
    font-weight: bold;
    font-size: 0.8em;
    color: rgb(0, 0, 0);
    left: -7% !important;
    top : 60% !important;
}

.table table th:nth-child(1),
.table table td:nth-child(1) {
  width: 12rem;
}

.table table th:nth-child(2),
.table table td:nth-child(2) {
  width: 10rem;
}

.table table th:nth-child(3),
.table table td:nth-child(3) {
  width: 15rem;
}

.table table th:nth-child(4),
.table table td:nth-child(4) {
  width: 7rem;
}

.table table th:nth-child(5),
.table table td:nth-child(5) {
  width: 10rem;
}

.table table th:nth-child(6),
.table table td:nth-child(6) {
  width: 8rem;
}

.table table th:nth-child(7),
.table table td:nth-child(7) {
  width: 7rem;
}

.table table th:nth-child(8),
.table table td:nth-child(8) {
  width: 10rem;
}

.table table th:nth-child(9),
.table table td:nth-child(9) {
  width: 7rem;
}

.table table th:nth-child(10),
.table table td:nth-child(10) {
  width: 7rem;
}

.toggle {
  display: flex;
  width: 100%;
  justify-content: end;
}